import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { removeToken } from '../services/Token'
import { useDispatch, useSelector } from 'react-redux';
import { unSetUserInfo } from '../fetures/userSlice';
import { BASEURL } from '../Config';

export default function Header() {

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);


  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  };


  useEffect(() => {
    if (isSidebarVisible) {
      document.body.classList.add('toggle-sidebar');
    } else {
      document.body.classList.remove('toggle-sidebar');
    }

    // Cleanup function to remove the class when the component unmounts or state changes
    return () => {
      document.body.classList.remove('toggle-sidebar');
    };
  }, [isSidebarVisible]);


  const auth = useSelector((state)=>state.user_info.data)


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = (e)=>{
    e.preventDefault()

    dispatch(unSetUserInfo());

   removeToken();
    navigate('/')
  }
  return (
   <>
    <header id="header" className="header fixed-top d-flex align-items-center">

<div className="d-flex align-items-center justify-content-between">
  <Link to='#' className="logo d-flex align-items-center">
    <img src="/assets/img/logo.svg" alt="" style={{ maxHeight:` 50px` }}/>
    {/* <span className="d-none d-lg-block">Medicina</span> */}
  </Link>
  <i className="bi bi-list toggle-sidebar-btn"   onClick={toggleSidebar}></i>
</div>

{/* <div className="search-bar">
  <form className="search-form d-flex align-items-center" method="POST" action="#">
    <input type="text" name="query" placeholder="Search" title="Enter search keyword"/>
    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
  </form>
</div> */}

<nav className="header-nav ms-auto">
  <ul className="d-flex align-items-center">



    <li className="nav-item dropdown pe-3">

      <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="#" data-bs-toggle="dropdown">
        <img src={BASEURL+''+auth.profile} className="rounded-circle" />
        <span className="d-none d-md-block dropdown-toggle ps-2">{auth.name}</span>
      </Link>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>QMedicina</h6>
          {/* <span>Web Designer</span> */}
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <Link className="dropdown-item d-flex align-items-center" to={`/admin/profile`}>
            <i className="bi bi-person"></i>
            <span>Meu perfil</span>
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <Link className="dropdown-item d-flex align-items-center" to={`/admin/change-password`}>
            <i className="bi bi-gear"></i>
            <span>Alterar a senha</span>
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <Link onClick={logout} className="dropdown-item d-flex align-items-center" to="#">
            <i className="bi bi-box-arrow-right"></i>
            <span>Sair</span>
          </Link>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>
   </>
  )
}
