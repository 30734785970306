import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASEURL } from '../Config';
export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL+'/api/' }),
  endpoints: (builder) => ({

    adminLogin:builder.mutation({
        query:(form_data)=>{
          // console.log(form_data)
            return{
                url:'admin-login',
                method:'post',
                body: form_data, 
               
            }
        }
    }),
    adminProfile:builder.mutation({
      query:({year,token})=>{
        console.log(token)
        return {
          url:`admin/admin-profile/${year}`,
          method:`get`,
          // body:{},
          headers:{
            authorization:`Bearer ${token}`
          }
        }
      }
    }),
    updateAdminProfile:builder.mutation({
      query:({token,form_data})=>{
        console.log(token)
        return{
          url:`admin/update-admin-profile`,
          method:'post',
          body:form_data,
          headers:{
            authorization:`bearer ${token}`
          }
        }
      }
    })
    ,
    adminChangePassword:builder.mutation({
      query:({token,form_data})=>{
      // console.log(form_data)
        return{
          url:`admin/change-password`,
          method:'post',
          body:form_data,
          headers:{
            authorization:`Bearer ${token}`
          }
        }
      }
    }),


uploadImage: builder.mutation({
      query: ({ token, formData }) => ({
        url: 'admin/upload-image',
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formData,
      }),
    }),

    userList:builder.mutation({
      query:({token,keyword,status,page=1,limit=50})=>{
        let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);

        return{
          url:`admin/users?${queryParams.toString()}`,
          method:'get',
          // body:{},
          headers:{
            // 'ContentType':'Application-json',
            authorization:`Bearer ${token}`
          }
        }
      }
    }),
    

    addUser:builder.mutation({
      query:({token,form_data})=>{
        return{
          url:'admin/users',
          method:'post',
          body:form_data,
          headers:{
            authorization:`Bearer ${token}`
          }
        }
      }
    }),
    editUser:builder.mutation({
      query:({token,id})=>{
        return {
          url:`admin/users/${id}/edit`,
          method:'get',
          // body:{},
          headers:{
            authorization:`Bearer ${token}`
          }
        }
      }
    }),
    updateUser:builder.mutation({
      query:({id,token,form_data})=>{
        return{
          url:'admin/users/'+id,
          method:'post',
          body:form_data,
          headers:{
            authorization:`Bearer ${token}`
          }

        }
      }
    }),
  
  DeleteUser:builder.mutation({
    query:({token,id})=>{
      return{
        url:'admin/users/'+id,
        method:'delete',
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),

  changeStatus:builder.mutation({
    query:({token,form_data})=>{
      return{
        url:`admin/change-status/_id/${form_data.table_name}/${form_data.id}/${form_data.status}`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),

  // Testimonial 

  testimonialList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/testimonials?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addTestimonial:builder.mutation({
    query:({token,form_data})=>{
      return{
        url:'admin/testimonials',
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editTestimonial:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/testimonials/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updateTestimonial:builder.mutation({
    query:({id,token,form_data})=>{
      return{
        url:'admin/testimonials/'+id,
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),

deleteTestimonial:builder.mutation({
  query:({token,id})=>{
    return{
      url:'admin/testimonials/'+id,
      method:'delete',
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),

//  Request demo  
requestDemoList:builder.mutation({
  query:({token,keyword,status,page=1,limit=50})=>{
    let queryParams = new URLSearchParams();
     
      if (keyword) {
        queryParams.append('keyword', keyword);
        // page = 1; // Reset page to 1 if keyword is present
      }
  
      if (status) {
        queryParams.append('status', status);
        // page = 1
      }
  
      queryParams.append('page', page);
      queryParams.append('limit', limit);
      return{
        url:`admin/demo-requests?${queryParams.toString()}`,
        method:'get',
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


//  Hospital Request 
hospitalRequestList:builder.mutation({
  query:({token,keyword,status,page=1,limit=50})=>{
    let queryParams = new URLSearchParams();
     
      if (keyword) {
        queryParams.append('keyword', keyword);
        // page = 1; // Reset page to 1 if keyword is present
      }
  
      if (status) {
        queryParams.append('status', status);
        // page = 1
      }
  
      queryParams.append('page', page);
      queryParams.append('limit', limit);
      return{
        url:`admin/hospital-requests?${queryParams.toString()}`,
        method:'get',
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


// Contactus 

contactUsList:builder.mutation({
  query:({token,keyword,status,question,page=1,limit=50})=>{
        let queryParams = new  URLSearchParams();

        if(keyword){
          queryParams.append('keyword',keyword);
        }

        if(status){
          queryParams.append('status',status)
        }
        if(question){
          queryParams.append('question',question)
        }
        queryParams.append('page',page);
        queryParams.append('limit',limit)
    return {
      url:`admin/contact-us?${queryParams}`,
      method:`get`,
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),

// Question 

questionList:builder.mutation({
  query:({token,keyword,status,page,limit})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('staus',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);

    return{
      url:`admin/questions?${queryParams}`,
      method:`get`,
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),

// FaqCategory 

faqCategoryList:builder.mutation({
    query:({token,keyword='',status='',page,limit=50})=>{
      const queryParams = new URLSearchParams();
      if(keyword){
        queryParams.append('keyword',keyword)
      }
  
      if(status){
        queryParams.append('status',status)
      }
  
      queryParams.append('page',page);
      queryParams.append('limit',limit);
    return{
      url:`admin/faq-category?${queryParams}`,
      method:`get`,
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),
addFaqCategory:builder.mutation({
  query:({token,data})=>{
    return{
      url:'admin/faq-category',
      method:'post',
      body:data,
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),
editFaqCategory:builder.mutation({
  query:({token,id})=>{
    return {
      url:`admin/faq-category/${id}/edit`,
      method:'get',
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),
updateFaqCategory:builder.mutation({
  query:({id,token,data})=>{
    return{
      url:'admin/faq-category/'+id,
      method:'post',
      body:data,
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),

deleteFaqCategory:builder.mutation({
query:({token,id})=>{
  return{
    url:'admin/faq-category/'+id,
    method:'delete',
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),

// Faq

faqList:builder.mutation({
  query:({token,keyword,faqCategoryId,status,page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    if(faqCategoryId){
      queryParams.append('faqCategoryId',faqCategoryId)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/faq?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addFaq:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/faq',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editFaq:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/faq/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updateFaq:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/faq/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deleteFaq:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/faq/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),
downloadPdfFaq: builder.mutation({
  query: ({ token }) => ({
    url: 'admin/faq/download/pdf',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseHandler: (response) => response.blob(), // This handles the binary response for PDF
  }),
}),



  // Blog 

  blogList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/blogs?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addBlog:builder.mutation({
    query:({token,form_data})=>{
      return{
        url:'admin/blogs',
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editBlog:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/blogs/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updateBlog:builder.mutation({
    query:({id,token,form_data})=>{
      return{
        url:'admin/blogs/'+id,
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),
  deleteBlog:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/blogs/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


  // Case-studies 

  caseStudiesList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/case-studies?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addCaseStudies:builder.mutation({
    query:({token,form_data})=>{
      return{
        url:'admin/case-studies',
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editCaseStudies:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/case-studies/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updateCaseStudies:builder.mutation({
    query:({id,token,form_data})=>{
      return{
        url:'admin/case-studies/'+id,
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),
  deleteCaseStudies:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/case-studies/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


  // Videos 

  videoList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/videos?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addVideo:builder.mutation({
    query:({token,form_data})=>{
      return{
        url:'admin/videos',
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editVideo:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/videos/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updateVideo:builder.mutation({
    query:({id,token,form_data})=>{
      return{
        url:'admin/videos/'+id,
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),
  deleteVideo:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/videos/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),

  // Patient 

  patientList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
          // page = 1; // Reset page to 1 if keyword is present
        }
    
        if (status) {
          queryParams.append('status', status);
          // page = 1
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/patients?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addPatient:builder.mutation({
    query:({token,data})=>{
      return{
        url:'admin/patients',
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editPatient:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/patients/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updatePatient:builder.mutation({
    query:({id,token,form_data})=>{
      return{
        url:'admin/patients/'+id,
        method:'post',
        body:form_data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),
  deletePatient:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/patients/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// ParentCategory 

parentCategoryList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/parent-category?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addParentCategory:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/parent-category',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editParentCategory:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/parent-category/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updateParentCategory:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/parent-category/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deleteParentCategory:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/parent-category/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),

// Category

categoryList:builder.mutation({
query:({token,keyword,parentCategoryId,status,page,limit=50})=>{
  const queryParams = new URLSearchParams();
  if(keyword){
    queryParams.append('keyword',keyword)
  }

  if(status){
    queryParams.append('status',status)
  }

  if(parentCategoryId){
    queryParams.append('parentCategoryId',parentCategoryId)
  }

  queryParams.append('page',page);
  queryParams.append('limit',limit);
return{
  url:`admin/category?${queryParams}`,
  method:`get`,
  // body:{},
  headers:{
    authorization:`Bearer ${token}`
  }

}
}
}),
addCategory:builder.mutation({
query:({token,data})=>{
return{
  url:'admin/category',
  method:'post',
  body:data,
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),
editCategory:builder.mutation({
query:({token,id})=>{
return {
  url:`admin/category/${id}/edit`,
  method:'get',
  // body:{},
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),
updateCategory:builder.mutation({
query:({id,token,data})=>{
return{
  url:'admin/category/'+id,
  method:'post',
  body:data,
  headers:{
    authorization:`Bearer ${token}`
  }

}
}
}),

deleteCategory:builder.mutation({
query:({token,id})=>{
return{
url:'admin/category/'+id,
method:'delete',
headers:{
  authorization:`Bearer ${token}`
}
}
}
}),



  // Procedure Category  

  procedureCategoryList:builder.mutation({
    query:({token})=>{
     
        return{
          url:`admin/procedure-category`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),


    // Procedure Sub Category  

    procedureSubCategoryList:builder.mutation({
      query:({token,procedureCategoryId})=>{
       
          return{
            url:`admin/procedure-sub-category`,
            method:'post',
            body:procedureCategoryId,
            headers:{
              authorization:`Bearer ${token}`
            }
          }
      }
    }),

// 

procedureDataBySubCategory:builder.mutation({
  query:({token,procedureSubCategoryId})=>{
   
      return{
        url:`admin/procedure-data-by-sub-category`,
        method:'post',
        body:procedureSubCategoryId,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


// select popular category for website 

selectWebsiteCategory:builder.mutation({
  query:({token,data})=>{
      return{
        url:`admin/select-website-category`,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


  // Page 

  pageList:builder.mutation({
    query:({token,keyword,status,page=1,limit=50})=>{
      let queryParams = new URLSearchParams();
       
        if (keyword) {
          queryParams.append('keyword', keyword);
        }
    
        if (status) {
          queryParams.append('status', status);
        }
    
        queryParams.append('page', page);
        queryParams.append('limit', limit);
        return{
          url:`admin/page?${queryParams.toString()}`,
          method:'get',
          headers:{
            authorization:`Bearer ${token}`
          }
        }
    }
  }),
  addPage:builder.mutation({
    query:({token,data})=>{
      return{
        url:'admin/page',
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  editPage:builder.mutation({
    query:({token,id})=>{
      return {
        url:`admin/page/${id}/edit`,
        method:'get',
        // body:{},
        headers:{
          authorization:`Bearer ${token}`
        }
      }
    }
  }),
  updatePage:builder.mutation({
    query:({id,token,data})=>{
      return{
        url:'admin/page/'+id,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }

      }
    }
  }),
  deletePage:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/page/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// Setting 

editSetting:builder.mutation({
  query:({token})=>{
    return {
      url:`admin/setting/edit`,
      method:'get',
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),

updateSetting:builder.mutation({
  query:({token,data})=>{
    return{
      url:'admin/setting/update',
      method:'post',
      body:data,
      headers:{
        authorization:`Bearer ${token}`
      },
    }
  }
}),


// Healthcare institute 


healthcareInstituteList:builder.mutation({
  query:({token,providerType,keyword,status,page=1,limit=50})=>{
    let queryParams = new URLSearchParams();
     
      if (keyword) {
        queryParams.append('keyword', keyword);
      }
  
      if (status) {
        queryParams.append('status', status);
      }
  
      queryParams.append('providerType', providerType);
      queryParams.append('page', page);
      queryParams.append('limit', limit);

    return{
      url:`admin/healthcare-institutelist?${queryParams}`,
      method:`get`,
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),


healthcareInstituteDetail:builder.mutation({
  query:({token,id})=>{
    return {
      url:`admin/healthcare-institutelist/${id}`,
      method:'get',
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),

// Reset password link 
resetPasswordProvider:builder.mutation({
  query:({token,id})=>{
    return {
      url:`admin/provider/reset-password-link`,
      method:'post',
      body:{id},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),


// Healthcare Professional 


healthcareProfessionalList:builder.mutation({
  query:({token,providerType,keyword,status,page=1,limit=50})=>{
    let queryParams = new URLSearchParams();
     
      if (keyword) {
        queryParams.append('keyword', keyword);
      }
  
      if (status) {
        queryParams.append('status', status);
      }
      queryParams.append('providerType', providerType);
      queryParams.append('page', page);
      queryParams.append('limit', limit);

    return{
      url:`admin/healthcare-professionallist?${queryParams}`,
      method:`get`,
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }

    }
  }
}),


healthcareProfessionalDetail:builder.mutation({
  query:({token,id})=>{
    return {
      url:`admin/healthcare-professionallist/${id}`,
      method:'get',
      // body:{},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),



// Reset password link 
resetPasswordProviderForProfessional:builder.mutation({
  query:({token,id})=>{
    return {
      url:`admin/provider/reset-password-link-for-professional`,
      method:'post',
      body:{id},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),



// Cbhpm 

cbhpmList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/cbhpm?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addCbhpm:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/cbhpm',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editCbhpm:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/cbhpm/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updateCbhpm:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/cbhpm/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deleteCbhpm:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/cbhpm/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),

uploadCbhpmFile:builder.mutation({
  query:({token,form_data})=>{
    return{
      url:`admin/upload-cbhpm-file`,
      method:'post',
      body:form_data,
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
 }),

 updateCbhpmAssignRole:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/update-cbhpm-assign-role',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

// Price Management 


// Anesthelogist Group Price Management

priceAnesthesiologistGroupList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-anesthesiologist-group?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPriceAnesthesiologistGroup:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-anesthesiologist-group',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPriceAnesthesiologistGroup:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-anesthesiologist-group/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePriceAnesthesiologistGroup:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-anesthesiologist-group/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePriceAnesthesiologistGroup:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-anesthesiologist-group/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// Freelance Anesthelogist

priceAnesthesiologistList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-freelance-anesthesiologist?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPriceAnesthesiologist:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-freelance-anesthesiologist',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPriceAnesthesiologist:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-freelance-anesthesiologist/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePriceAnesthesiologist:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-freelance-anesthesiologist/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePriceAnesthesiologist:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-freelance-anesthesiologist/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// Pathology Price manaqgement 


pricePathologyList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-pathology?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPricePathology:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-pathology',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPricePathology:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-pathology/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePricePathology:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-pathology/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePricePathology:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-pathology/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// Surgeon Price manaqgement 


priceSurgeonList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-surgeon?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPriceSurgeon:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-surgeon',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPriceSurgeon:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-surgeon/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePriceSurgeon:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-surgeon/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePriceSurgeon:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-surgeon/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),




// Laboratory Price manaqgement 


priceLaboratoryList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-laboratory?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPriceLaboratory:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-laboratory',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPriceLaboratory:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-laboratory/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePriceLaboratory:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-laboratory/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePriceLaboratory:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-laboratory/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),



// Image Diagnostic Price manaqgement 


priceImageDiagnosticList:builder.mutation({
  query:({token,keyword='',status='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(status){
      queryParams.append('status',status)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
  return{
    url:`admin/price-management-image-diagnostic?${queryParams}`,
    method:`get`,
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),
addPriceImageDiagnostic:builder.mutation({
query:({token,data})=>{
  return{
    url:'admin/price-management-image-diagnostic',
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
editPriceImageDiagnostic:builder.mutation({
query:({token,id})=>{
  return {
    url:`admin/price-management-image-diagnostic/${id}/edit`,
    method:'get',
    // body:{},
    headers:{
      authorization:`Bearer ${token}`
    }
  }
}
}),
updatePriceImageDiagnostic:builder.mutation({
query:({id,token,data})=>{
  return{
    url:'admin/price-management-image-diagnostic/'+id,
    method:'post',
    body:data,
    headers:{
      authorization:`Bearer ${token}`
    }

  }
}
}),

deletePriceImageDiagnostic:builder.mutation({
query:({token,id})=>{
return{
  url:'admin/price-management-image-diagnostic/'+id,
  method:'delete',
  headers:{
    authorization:`Bearer ${token}`
  }
}
}
}),


// Update permission  Healthcare professional

updatePermissionhealthcareProfession:builder.mutation({
  query:({token,id,form_data})=>{
    return {
      url:`admin/update-permission-healthcare-professional/${id}`,
      method:'post',
      body:{form_data},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),


// Update permission  Healthcare Institutation

updatePermissionhealthcareInstitution:builder.mutation({
  query:({token,id,form_data})=>{
    return {
      url:`admin/update-permission-healthcare-institution/${id}`,
      method:'post',
      body:{form_data},
      headers:{
        authorization:`Bearer ${token}`
      }
    }
  }
}),

// select popular category for website 

selectReviewProvider:builder.mutation({
  query:({token,data})=>{
      return{
        url:`admin/select-review-provider`,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),

// Order History 

orderHistory:builder.mutation({
  query:({token,keyword='',surgoeryStatus='',startDate='',endDate='',page,limit=50})=>{
    const queryParams = new URLSearchParams();
    if(keyword){
      queryParams.append('keyword',keyword)
    }

    if(surgoeryStatus){
      queryParams.append('surgoeryStatus',surgoeryStatus)
    }

    if(startDate){
      queryParams.append('startDate',startDate)
    }

    if(endDate){
      queryParams.append('endDate',endDate)
    }

    queryParams.append('page',page);
    queryParams.append('limit',limit);
      return{
        url:`admin/order-history?${queryParams}`,
        method:'get',
        // body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),

// Update Appointment Date 

updateAppointment:builder.mutation({
  query:({token,data})=>{
    const queryParams = new URLSearchParams();
    
      return{
        url:`admin/update-Appointment`,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


// update multiple ids status 

updateMultipleStatus:builder.mutation({
  query:({token,data})=>{
      return{
        url:`admin/update-multiple-status`,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),


// Completed order  status 

complateOrderStatus:builder.mutation({
  query:({token,data})=>{
      return{
        url:`admin/complete-order-status`,
        method:'post',
        body:data,
        headers:{
          authorization:`Bearer ${token}`
        }
      }
  }
}),



})
})
export const { 
  // Admin Login
  useAdminLoginMutation,

  // Admin Basic functionality
  useAdminProfileMutation,
  useUpdateAdminProfileMutation,
  useAdminChangePasswordMutation,
  useChangeStatusMutation,

  // Ckeditor
  useUploadImageMutation,
  
  // User 
  useUserListMutation ,
  useAddUserMutation,
  useEditUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  // Testimonail
  useTestimonialListMutation ,
  useAddTestimonialMutation,
  useEditTestimonialMutation,
  useUpdateTestimonialMutation,
  useDeleteTestimonialMutation,
  // RequestDemo 
  useRequestDemoListMutation,

// Hospital Request 
useHospitalRequestListMutation,

// Contact us
  useContactUsListMutation ,

  // Question 
  useQuestionListMutation,
  // FaqCategory
  useFaqCategoryListMutation,
  useAddFaqCategoryMutation,
  useEditFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
 // Faq
  useFaqListMutation,
  useAddFaqMutation,
  useEditFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
  useDownloadPdfFaqMutation,
  // Blog
  useBlogListMutation,
  useAddBlogMutation,
  useEditBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  // Case Studies
  useCaseStudiesListMutation,
  useAddCaseStudiesMutation,
  useEditCaseStudiesMutation,
  useUpdateCaseStudiesMutation,
  useDeleteCaseStudiesMutation,
 // Video
  useVideoListMutation,
  useAddVideoMutation,
  useEditVideoMutation,
  useUpdateVideoMutation,
  useDeleteVideoMutation,
  // Patient
  usePatientListMutation,
  useAddPatientMutation,
  useEditPatientMutation,
  useUpdatePatientMutation,
  useDeletePatientMutation,
  // Parent Category
  useParentCategoryListMutation,
  useAddParentCategoryMutation,
  useEditParentCategoryMutation,
  useUpdateParentCategoryMutation,
  useDeleteParentCategoryMutation,
  // Category
  useCategoryListMutation,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

useProcedureCategoryListMutation,
useProcedureSubCategoryListMutation,
useProcedureDataBySubCategoryMutation,

useSelectWebsiteCategoryMutation,


  // Page
  usePageListMutation,
  useAddPageMutation,
  useEditPageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,

    // Setting
    useEditSettingMutation,
    useUpdateSettingMutation,
  
// HealthCare Institute 

useHealthcareInstituteListMutation,
useHealthcareInstituteDetailMutation,
useResetPasswordProviderMutation,


// HealthCare Professional

useHealthcareProfessionalListMutation,
useHealthcareProfessionalDetailMutation,
useResetPasswordProviderForProfessionalMutation,

// Cbhpm 

useCbhpmListMutation,
useAddCbhpmMutation,
useEditCbhpmMutation,
useUpdateCbhpmMutation,
useDeleteCbhpmMutation,
useUploadCbhpmFileMutation,
useUpdateCbhpmAssignRoleMutation,


// Price Management 

// price-management-anesthesiologist-group

usePriceAnesthesiologistGroupListMutation,
useAddPriceAnesthesiologistGroupMutation,
useEditPriceAnesthesiologistGroupMutation,
useUpdatePriceAnesthesiologistGroupMutation,
useDeletePriceAnesthesiologistGroupMutation,

// price-management-freelance-anesthesiologist

usePriceAnesthesiologistListMutation,
useAddPriceAnesthesiologistMutation,
useEditPriceAnesthesiologistMutation,
useUpdatePriceAnesthesiologistMutation,
useDeletePriceAnesthesiologistMutation,

// Price-management pathology

usePricePathologyListMutation,
useAddPricePathologyMutation,
useEditPricePathologyMutation,
useUpdatePricePathologyMutation,
useDeletePricePathologyMutation,

// Price-management surgeon

usePriceSurgeonListMutation,
useAddPriceSurgeonMutation,
useEditPriceSurgeonMutation,
useUpdatePriceSurgeonMutation,
useDeletePriceSurgeonMutation,

// Price-management Laboratory

usePriceLaboratoryListMutation,
useAddPriceLaboratoryMutation,
useEditPriceLaboratoryMutation,
useUpdatePriceLaboratoryMutation,
useDeletePriceLaboratoryMutation,

// Price-management Laboratory

usePriceImageDiagnosticListMutation,
useAddPriceImageDiagnosticMutation,
useEditPriceImageDiagnosticMutation,
useUpdatePriceImageDiagnosticMutation,
useDeletePriceImageDiagnosticMutation,


// update permission healthcare professional 

useUpdatePermissionhealthcareProfessionMutation,

// update permission healthcare Institution 

useUpdatePermissionhealthcareInstitutionMutation,


// select review provider 
useSelectReviewProviderMutation,

// Order History 
useOrderHistoryMutation,


// Update Apointment Date 

useUpdateAppointmentMutation,

// Update multiple status 
useUpdateMultipleStatusMutation,


// Complete Orde Status
useComplateOrderStatusMutation,


} = projectApi