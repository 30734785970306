import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';
import { useComplateOrderStatusMutation, useOrderHistoryMutation, useUpdateAppointmentMutation } from '../../../services/ProjectApi';
import { addDays, format, parse } from 'date-fns';

import DatePicker from 'react-datepicker';
import Loader from '../../../components/loader/Loader';

export default function Transaction() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const handleApiError = useApiErrorHandler();

    const [OrderHistoryMutation] = useOrderHistoryMutation();
    // const [deleteCategoryMutation] = useDeleteCategoryMutation();
    // const [ChangeStatusMutation] = useChangeStatusMutation();

    const [loading,setLoading] = useState(false)

    const getList = async ()=>{
        try{
            setLoading(true)
        const OrderHistoryMutationResp = await OrderHistoryMutation({token,keyword:searchInput.keyword,surgoeryStatus:searchInput.surgoeryStatus,startDate:searchInput.startDate
            ,endDate:searchInput.endDate,page:currentPage});
        // const OrderHistoryMutationResp = await OrderHistoryMutation({token,page:currentPage});
       
    
      if(OrderHistoryMutationResp.error){

        handleApiError(OrderHistoryMutationResp.error)
      }
       
        if(OrderHistoryMutationResp.data.status){
            setList(OrderHistoryMutationResp.data.data);
            setTotalPages(OrderHistoryMutationResp.data.pagination.last_page);
        }
        setLoading(false)
    } catch (error) {
        // console.log('vikas')
        // if (error?.statusCode === 403) {
        // console.log(error)

            // toast.error('You are not authorized to perform this action.');
            // removeToken();
            // navigate('/')
        // }
    }
    }

    useEffect(()=>{
        getList();
    },[]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput, currentPage]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            // const deleteUserMutationResp = await deleteCategoryMutation({token,id});
            // if(deleteCategoryMutationResp.data.status){
                getList();
                // notifySuccuess(deleteUserMutationResp.data.message);
            // } else {
                // notifyError(deleteUserMutationResp.data.message);
            // }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutationResp({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


const [errorData,setErrorData] =  useState({})

const [updateAppointmentMutation] = useUpdateAppointmentMutation()

const handleDateChange = async (id, newDate) => {
    setLoading(true)

    if (!newDate) return;

    // const formattedDate = format(newDate, "dd-MM-yyyy");
    const appointmentDate = newDate.toISOString();

    const data = {id,appointmentDate}

    try {
      const response = await updateAppointmentMutation({ token, data});
  

      if(response.data.status){
        notifySuccuess('Appointment Date is Updated.')
        getList();
      }else{
        notifyError('Something went wrong')
      }
     
    } catch (error) {
      notifyError("Error updating appointment date.");
    }
    setLoading(false)
  };

  const [filteredOrders, setFilteredOrders] = useState([list]);


  
  const [checkRow,setCheckRow] = useState([]);

  const [ComplateOrderStatusMutation] = useComplateOrderStatusMutation();


const handleCheckbox = async ( e,transactionId)=>{
    setLoading(true)
    if(e.target.checked){
        setCheckRow([...checkRow,transactionId])
    }else{
        setCheckRow(checkRow.filter(i=> i!== transactionId))
    }

    const data = {transactionId}
  
    const ComplateOrderStatusMutationResp = await ComplateOrderStatusMutation({token,data});
    console.log(ComplateOrderStatusMutationResp)
    if(ComplateOrderStatusMutationResp.data.status){
        notifySuccuess('Procedimento concluído com sucesso.')
        getList();
      }else{
        notifyError('Something went wrong')
      }

      setLoading(false)
}   


  return (
    <Layout>
    <ToastContainer />
    {loading && <Loader />}
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Lista de transações</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    {/* <Link className="btn btn-primary" to={`/admin/create-user`}>Add Transaction</Link> */}
                </div>
            </div>
        </div>
        <div className="card-body " style={{ overflowX: 'scroll' }}>
            <div className="row mb-3">
                <div className="col-md-3">
                <label>Palavra-chave</label>
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Pesquise aqui..' 
                    />
                </div>
                <div className="col-md-3">
                <label>Status da cirurgia</label>
                    <select 
                        name='surgoeryStatus' 
                        value={searchInput.surgoeryStatus} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, surgoeryStatus: e.target.value})
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Selecione o status da cirurgia--</option>
                        <option value='pending'>Pendente</option>
                        <option value='accept'>Aceito</option>
                        <option value='reject'>Rejeitada</option>
                        <option value='completed'>Concluída</option>
                    </select>
                </div>

                <div className="col-md-3">
                <label>Da data</label>
                <DatePicker
        //   defaultValue={addDays(new Date(), 10)} 
        //   minDate={addDays(new Date(), 10)} 

          selected={searchInput.startDate ? parse(searchInput.startDate, "dd/MM/yyyy", new Date()) : null} 

          onChange={(date) => {
            if (date) {
              const formattedDate = format(date, "dd/MM/yyyy");
              setSearchInput({ ...searchInput, startDate: formattedDate });
              setErrorData({ ...errorData, startDate: "" });
            } else {
              setSearchInput({ ...searchInput, startDate: null });
              setErrorData({
                ...errorData,
                startDate: "Invalid date. Please select a valid date.",
              });
            }
          }}
          dateFormat="dd/MM/yyyy" // Display format
          className={`form-control ${
            errorData.startDate ? "is-invalid" : ""
          }`}
          name="startDate"
          id="startDate"
          placeholder="Select Date"
          autoComplete="off"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
        />
                </div>

                <div className="col-md-3">
                <label>A data</label>
                <DatePicker
          defaultValue={searchInput.startDate} 
          minDate={searchInput.startDate} 

          selected={searchInput.endDate ? parse(searchInput.endDate, "dd/MM/yyyy", new Date()) : null} 

          onChange={(date) => {
            if (date) {
              const formattedDate = format(date, "dd/MM/yyyy");
              setSearchInput({ ...searchInput, endDate: formattedDate });
              setErrorData({ ...errorData, endDate: "" });
            } else {
              setSearchInput({ ...searchInput, endDate: null });
              setErrorData({
                ...errorData,
                endDate: "Invalid date. Please select a valid date.",
              });
            }
          }}
          dateFormat="dd/MM/yyyy" // Display format
          className={`form-control ${
            errorData.endDate ? "is-invalid" : ""
          }`}
          name="endDate"
          id="endDate"
          placeholder="Select Date"
          autoComplete="off"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          customInput={<input readOnly />}
        />
                </div>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        {/* <th scope="col">Imagem</th> */}
                        <th scope="col">Txn Id</th>
                        <th scope="col">GradTotal</th>
                        <th scope="col">Commision Amount</th>
                        <th scope="col">Nome do paciente</th>
                        <th scope="col">E-mail do paciente</th>
                        <th scope="col">Telefone do Paciente</th>
                        <th scope="col">Nome do provedor</th>
                        <th scope="col">E-mail do provedor</th>
                        <th scope="col">Telefone do provedor</th>
                        <th scope="col">Data da Consulta</th>
                        <th scope="col">Status da cirurgia</th>
                        <th scope="col">Está concluído</th>
                        {/* <th scope="col">Status</th> */}
                        {/* <th scope="col">Ação</th> */}
                    </tr>
                </thead>
                <tbody>
                {
                    filteredOrders.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                            {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                            <td>{index + 1}</td>
                            {/* <td><img src={`${BASEURL}${value.image}`} width="50px" height="50px" alt="User" /></td> */}
                            <td>{value.txnId}</td>
                            <td>{parseInt(value.grandTotal)}</td>
                            <td>{parseInt(  parseInt(value.grandTotal) * parseInt(value.commisionTax) / 100 ) }</td>
                            <td><Link className='text-primary' to={`/admin/patient/${value.patientId}/edit`} >{`${value.firstName} ${value.lastName}`}</Link></td>
                            <td>{value.email}</td>
                            <td>{value.phone}</td>
                            {value.cardData[0].type === 'professional' ? (
                                <td><Link className='text-primary' to={`/admin/healthcare-professionals/${value.providerId}`} >{value.cardData[0].type === 'professional' ? value.cardData[0].providerData.fullName : value.cardData[0].providerData.companyFantasyName }</Link></td>

                            ):(
                                <td><Link className='text-primary' to={`/admin/healthcare-institutions/${value.providerId}`} >{value.cardData[0].type === 'professional' ? value.cardData[0].providerData.fullName : value.cardData[0].providerData.companyFantasyName }</Link></td>

                            )}
                            <td>{ value.cardData[0].providerData.email }</td>
                            <td>{ value.cardData[0].providerData.phone }</td>
                            <td>
                            {value.surgeroyStatus === "pending" || value.surgeroyStatus === 'reject' || value.surgeroyStatus === 'accept' ? (
                        <DatePicker
                          selected={value.appointmentDate ? new Date(value.appointmentDate) : null}

                          onChange={(date) => handleDateChange(value._id, date)}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="Select Date"
                        />
                      ) : (
                        new Date(value?.appointmentDate).toLocaleDateString("en-GB")
                      )} </td>
                      <td>
                      {
                        value.surgeroyStatus === 'accept'
                            ? 'Accepted'
                            : value.surgeroyStatus === 'reject'
                            ? 'Rejected'
                            : value.surgeroyStatus === 'completed'
                            ? 'Completed'
                            : value.surgeroyStatus === 'failed'
                            ? 'Failed'
                            : 'Pending'
                        }

                            </td>

                            <td>
                                {value.surgeroyStatus === 'accept' ? (
                                    <>
                                    <td><input type='checkbox' checked={checkRow.includes(value._id)} onChange={(e)=>{ handleCheckbox(e,value._id)}} /></td>
                                    </>
                                ):'-'}
                            </td>

                            {/* <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('users', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('users', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td> */}
                            {/* <td>
                                <Link to={`/admin/user/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                            </td> */}
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>
</Layout>
  )
}
