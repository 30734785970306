// utils/formatPath.js
export const formatPath = (path) => {
    // Replace hyphens and underscores with spaces, then capitalize each word
    return path
        .replace(/[-_]/g, ' ')  // Replace hyphens and underscores with spaces
        .toLowerCase()          // Convert to lowercase
        .split(' ')             // Split into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize each word
        .join(' ');             // Join words with spaces
};
