import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {  useEditFaqMutation, useEditPageMutation, useFaqCategoryListMutation, useUpdateFaqMutation, useUpdatePageMutation, useUploadImageMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';



export default function EditPage() {

    const { MyCustomUploadAdapterPlugin } = useCkeditorUploadAdapter();
    const { id } = useParams();
    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({});

    const token = getToken();
    const handleApiError = useApiErrorHandler();
    const [UpdatePageMutation] = useUpdatePageMutation();
    const [EditPageMutation] = useEditPageMutation();

    const validateForm = () => {
        const errors = {};

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        if (!inputData.description || inputData.description.trim() === '') errors.description = 'O campo de descrição é obrigatório';

        setErrorData(errors);
        return Object.keys(errors).length === 0;
    };

    const navigate = useNavigate()
    const updateHandler = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const data = {
                    _id: id,
                    _method: 'PUT',
                    title: inputData.title,
                    description: inputData.description,
                };

                const response = await UpdatePageMutation({ id, token, data }).unwrap();
                if (response.status) {
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate("/admin/page"); 
                      }, 1000);
                } else {
                    setErrorData(response.errors || {});
                    toast.error(response.message);
                }
            } catch (error) {
                handleApiError(error);
            }
        }
    };



    const getPageDetailEdit = async () => {
        try {
            const response = await EditPageMutation({ token, id }).unwrap();
            if (response.status) {
                setInputData(response.data);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    useEffect(() => {
        getPageDetailEdit();
    }, [id, token]);

    const editorConfig = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
        extraPlugins: [MyCustomUploadAdapterPlugin],
    };

    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6'>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Edit Page Form</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/page`} className='btn btn-danger'>Voltar</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={updateHandler}>
                        <ToastContainer />

                        <div className='form-group'>
                           <label htmlFor='title'>Título *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, title: e.target.value });
                                    setErrorData({ ...errorData, title: '' });
                                }}
                                className={`form-control ${errorData.title ? 'is-invalid' : ''}`}
                                name='title'
                                value={inputData.title || ''}
                                id='title'
                            />
                            {errorData.title && <div className='invalid-feedback'>{errorData.title}</div>}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='description'>Descrição</label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfig}
                                id='description'
                                name='description'
                                data={inputData.description || ''}
                                onChange={(e, editor) => {
                                    const data = editor.getData();
                                    setInputData(prevData => ({ ...prevData, description: data }));
                                    setErrorData(prevErrors => ({ ...prevErrors, description: '' }));
                                }}
                            />
                        </div>

                      
                        <div className='my-3'>
                            <input type='submit' className='btn btn-primary mr-3' style={{ marginRight: `5px` }} value='Submit' />
                            <Link to={`/admin/page`} className='btn btn-danger ml-3'>Voltar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}
