import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';


import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../Config';
import { useChangeStatusMutation, useDeleteFaqCategoryMutation, useDeletePriceAnesthesiologistMutation, useDeletePricePathologyMutation, useFaqCategoryListMutation, usePriceAnesthesiologistListMutation, usePricePathologyListMutation } from '../../../../services/ProjectApi';
import { useApiErrorHandler } from '../../../../utils/useApiErrorHandler';
import { getToken } from '../../../../services/Token';
import Layout from '../../../../master/Layout';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../../components/loader/Loader';

export default function PricePathology() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const token = getToken();

   

    const handleApiError = useApiErrorHandler();

    const [PricePathologyListMutation] = usePricePathologyListMutation();
    const [DeletePricePathologyMutation] = useDeletePricePathologyMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();

 const [loading,setLoading] = useState(false)

    const getList = async ()=>{
        setLoading(true)
        try{
        const response = await PricePathologyListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
      if(response.error){

        handleApiError(response.error)
      }
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    setLoading(false)
    }

    useEffect(()=>{
        getList();
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);


    const deleteData = async (id) => {
        confirmAlert({
            title: 'Confirme para excluir',
            message: 'Tem certeza de que deseja excluir este item?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try {
                            const deleteResponse = await DeletePricePathologyMutation({ token, id });
                            if (deleteResponse.data.status) {
                                console.log(deleteResponse.data);
                                notifySuccuess(deleteResponse.data.message);
                                getList(); // Refresh the list after deletion
                            } else {
                                notifyError(deleteResponse.data.message);
                            }
                        } catch (error) {
                            notifyError('An error occurred while deleting the item.');
                            console.error(error);
                        }
                    },
                },
                {
                    label: 'Não',
                    onClick: () => console.log('Cancelled'),
                },
            ],
        });
    };


    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };




  return (
    <Layout>
    <ToastContainer />
    {loading && <Loader />}
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Lista de patologias de preços</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    {/* <Link className="btn btn-primary" to={`/admin/price-management-pathology/create`}>Add Price Pathology</Link> */}
                </div>
            </div>
        </div>
           <div className="card-body " style={{ overflowX: 'scroll' }}>
            {/* <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Pesquise aqui..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Por favor selecione--ione--</option>
                       <option value='active'>Ativo</option>
                       <option value='inactive'>Inativo</option>
                    </select>
                </div>
            </div> */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Não neoplásico caro</th>
                        <th scope="col">Preço neoplásico</th>
                        <th scope="col">Preço Operativo Congelado</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col">Ação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                           {/* <td>{index + 1}</td> */}
                           <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{value.nonNeoplasticPrice}</td>
                            <td>{value.neoplasticPrice}</td>
                            <td>{value.operativefrozenPrice}</td>
                            {/* <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('AdminPriceAesthesiologist', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('AdminPriceAesthesiologist', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td> */}
                            <td>
                                <Link to={`/admin/price-management-pathology/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                {/* <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link> */}
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>
</Layout>
  )
}
