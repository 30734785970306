import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Login from '../pages/login/Login'
import Dashbaord from '../pages/auth/dashboard/Dashbaord'
import User from '../pages/auth/user/User'
import AddUser from '../pages/auth/user/AddUser'
import EditUser from '../pages/auth/user/EditUser'
import Profile from '../pages/auth/profile/Profile'
import ChangePassword from '../pages/auth/change-password/ChangePassword'
import Surgeon from '../pages/auth/surgeon/Surgeon'
import Patient from '../pages/auth/patient/Patient'
import Transaction from '../pages/auth/transaction/Transaction'
import Blog from '../pages/auth/blog/Blog'
import DemoRequest from '../pages/auth/demo-request/DemoRequest'
import ContactUs from '../pages/auth/contact-us/ContactUs'
import FAQ from '../pages/auth/faq/FAQ'
import Testimonial from '../pages/auth/testimonial/Testimonial'
import Review from '../pages/auth/review/Review'
import Support from '../pages/auth/support/Support'
import Setting from '../pages/auth/setting/Setting'
import CreateTestimonial from '../pages/auth/testimonial/CreateTestimonial'
import EditTestimonial from '../pages/auth/testimonial/EditTestimonial'
import FAQCategory from '../pages/auth/faq-category/FAQCategory'
import CreateFAQCategory from '../pages/auth/faq-category/CreateFAQCategory'
import EditFAQCategory from '../pages/auth/faq-category/EditFAQCategory'
import CreateFAQ from '../pages/auth/faq/CreateFAQ'
import EditFAQ from '../pages/auth/faq/EditFAQ'
import CaseStudies from '../pages/auth/case-study/CaseStudies'
import CreateBlog from '../pages/auth/blog/CreateBlog'
import EditBlog from '../pages/auth/blog/EditBlog'
import CreateCaseStudies from '../pages/auth/case-study/CreateCaseStudies'
import EditCaseStudies from '../pages/auth/case-study/EditCaseStudies'
import Video from '../pages/auth/video/Video'
import CreateVideo from '../pages/auth/video/CreateVideo'
import EditVideo from '../pages/auth/video/EditVideo'
import CreatePatient from '../pages/auth/patient/CreatePatient'
import EditPatient from '../pages/auth/patient/EditPatient'
import ParentCategory from '../pages/auth/parent-category/ParentCategory'
import CreateParentCategory from '../pages/auth/parent-category/CreateParentCategory'
import EditParentCategory from '../pages/auth/parent-category/EditParentCategory'
import Category from '../pages/auth/category/Category'
import CreateCategory from '../pages/auth/category/CreateCategory'
import EditCategory from '../pages/auth/category/EditCategory'
import Page from '../pages/auth/page/Page'
import CreatePage from '../pages/auth/page/CreatePage'
import EditPage from '../pages/auth/page/EditPage'
import HealthcareInstitutions from '../pages/auth/healthcare-institution/HealthcareInstitutions'
import HealthcareProfessionals from '../pages/auth/healthcare-professionals/HealthcareProfessionals'
import HealthcareInstitutionsDetail from '../pages/auth/healthcare-institution/HealthcareInstitutionsDetail'
import HealthcareProfessionalsDetail from '../pages/auth/healthcare-professionals/HealthcareProfessionalsDetail'
import Cbhpm from '../pages/auth/cbhpm/Cbhpm'
import CreateCbhpm from '../pages/auth/cbhpm/CreateCbhpm'
import EditCbhpm from '../pages/auth/cbhpm/EditCbhpm'
import PriceAnesthesiologist from '../pages/auth/price-management/price-freelance-anesthesiologist/PriceAnesthesiologist'
import CreatePriceAnesthesiologist from '../pages/auth/price-management/price-freelance-anesthesiologist/CreatePriceAnesthesiologist'
import EditPriceAnesthesiologist from '../pages/auth/price-management/price-freelance-anesthesiologist/EditPriceAnesthesiologist'
import PricePathology from '../pages/auth/price-management/price-freelance-pathology/PricePathology'
import EditPricePathology from '../pages/auth/price-management/price-freelance-pathology/EditPricePathology'
import CreatePricePathology from '../pages/auth/price-management/price-freelance-pathology/CreatePricePathology'
import PriceSurgeon from '../pages/auth/price-management/price-freelance-surgeon/PriceSurgeon'
import CreatePriceSurgeon from '../pages/auth/price-management/price-freelance-surgeon/CreatePriceSurgeon'
import EditPriceSurgeon from '../pages/auth/price-management/price-freelance-surgeon/EditPriceSurgeon'
import PriceLaboratory from '../pages/auth/price-management/price-freelance-laboratory/PriceLaboratory'
import CreatePriceLaboratory from '../pages/auth/price-management/price-freelance-laboratory/CreatePriceLaboratory'
import EditPriceLaboratory from '../pages/auth/price-management/price-freelance-laboratory/EditPriceLaboratory'
import PriceImageDiagnostic from '../pages/auth/price-management/price-image-diagnostic/PriceImageDiagnostic'
import CreatePriceImageDiagnostic from '../pages/auth/price-management/price-image-diagnostic/CreatePriceImageDiagnostic'
import EditPriceImageDiagnostic from '../pages/auth/price-management/price-image-diagnostic/EditPriceImageDiagnostic'
import PriceAnesthesiologistGroup from '../pages/auth/price-management/price-anesthesiologist-group/PriceAnesthesiologistGroup'
import CreatePriceAnesthesiologistGroup from '../pages/auth/price-management/price-anesthesiologist-group/CreatePriceAnesthesiologistGroup'
import EditPriceAnesthesiologistGroup from '../pages/auth/price-management/price-anesthesiologist-group/EditPriceAnesthesiologistGroup'
import HospitalRequest from '../pages/auth/hospital-request/HospitalRequest'

export default function AppRoutes() {
  return (
    <>
    <Routes>
    <Route path='/' element={<PublicRoute />}>
    <Route path='/' element={<Login />} />
    </Route>

    <Route path='/admin/' element={<PrivateRoute />}>
      <Route path='dashboard' element={<Dashbaord />} />
      <Route path='change-password' element={<ChangePassword />} />
      <Route path='profile' element={<Profile />} />
      <Route path='users' element={<User />} />
      <Route path='create-user' element={<AddUser />} />
      <Route path='user/:id/edit' element={<EditUser />} />


  

    <Route path='surgeons' element={<Surgeon />} />



    {/* healthcare-institutions Routes */}
    <Route path='healthcare-institutions' element={<HealthcareInstitutions />} />
    <Route path='healthcare-institutions/:id' element={<HealthcareInstitutionsDetail />} />


    {/* Aneshtesia-group Routes */}
    <Route path='anesthesia-group' element={<HealthcareInstitutions />} />
    <Route path='anesthesia-group/:id' element={<HealthcareInstitutionsDetail />} />

     {/* Hospital Routes */}
     <Route path='hospital' element={<HealthcareInstitutions />} />
    <Route path='hospital/:id' element={<HealthcareInstitutionsDetail />} />

     {/* Imaging-diagnostic-clinic Routes */}
     <Route path='imaging-diagnostic-clinic' element={<HealthcareInstitutions />} />
    <Route path='imaging-diagnostic-clinic/:id' element={<HealthcareInstitutionsDetail />} />
    
      {/* Laboratory Routes */}
      <Route path='laboratory' element={<HealthcareInstitutions />} />
    <Route path='laboratory/:id' element={<HealthcareInstitutionsDetail />} />

   

     {/*  Pathology-clinic Routes */}
     <Route path='pathology-clinic' element={<HealthcareInstitutions />} />
    <Route path='pathology-clinic/:id' element={<HealthcareInstitutionsDetail />} />

     {/*  SpecialMaterialsCompany Routes */}
     <Route path='special-materials-company' element={<HealthcareInstitutions />} />
    <Route path='special-materials-company/:id' element={<HealthcareInstitutionsDetail />} />
    


     {/* healthcare-professionals Routes */}
     <Route path='healthcare-professionals' element={<HealthcareProfessionals />} />
     <Route path='healthcare-professionals/:id' element={<HealthcareProfessionalsDetail />} />


         {/*  Surgeon Routes */}
         <Route path='surgeon' element={<HealthcareProfessionals />} />
    <Route path='surgeon/:id' element={<HealthcareProfessionalsDetail />} />


         {/*  Freelance-anesthesiologist Routes */}
         <Route path='freelance-anesthesiologist' element={<HealthcareProfessionals />} />
    <Route path='freelance-anesthesiologist/:id' element={<HealthcareProfessionalsDetail />} />


    
           {/*  General Practitioner Routes */}
           <Route path='general-practitioner' element={<HealthcareProfessionals />} />
    <Route path='general-practitioner/:id' element={<HealthcareProfessionalsDetail />} />


           {/* SpecialistClinician Routes */}
           <Route path='specialist-clinician' element={<HealthcareProfessionals />} />
    <Route path='specialist-clinician/:id' element={<HealthcareProfessionalsDetail />} />

            {/* OtherHealthProfessional Routes */}
            <Route path='other-health-professional' element={<HealthcareProfessionals />} />
    <Route path='other-health-professional/:id' element={<HealthcareProfessionalsDetail />} />
     
    


    


 {/* Category Route */}
    <Route path='patients' element={<Patient />} />
    <Route path='patient/create' element={<CreatePatient />} />
    <Route path='patient/:id/edit' element={<EditPatient />} />

 {/* Blog Route */}
    <Route path='blogs' element={<Blog />} />
    <Route path='blog/create' element={<CreateBlog />} />
    <Route path='blog/:id/edit' element={<EditBlog />} />


{/*  Case Studies Route */}
    <Route path='case-studies' element={<CaseStudies />} />
    <Route path='case-studies/create' element={<CreateCaseStudies />} />
    <Route path='case-studies/:id/edit' element={<EditCaseStudies />} />


{/*  Video Route */}
    <Route path='videos' element={<Video />} />
    <Route path='video/create' element={<CreateVideo />} />
    <Route path='video/:id/edit' element={<EditVideo />} />



    <Route path='transactions' element={<Transaction />} />

    <Route path='demo-requests' element={<DemoRequest />} />

    <Route path='hospital-requests' element={<HospitalRequest />} />

    <Route path='contact-us' element={<ContactUs />} />

{/* Faq Category Route */}
    <Route path='FAQ-category' element={<FAQCategory />} />

    <Route path='FAQ-category/create' element={<CreateFAQCategory />} />
    <Route path='FAQ-category/:id/edit' element={<EditFAQCategory />} />

    {/* Faq Route */}

    <Route path='FAQ' element={<FAQ />} />
    <Route path='FAQ/create' element={<CreateFAQ />} />
    <Route path='FAQ/:id/edit' element={<EditFAQ />} />

    {/*  Testimonail Route  */}
    <Route path='testimonials' element={<Testimonial />} />
    <Route path='testimonial/create' element={<CreateTestimonial />} />
    <Route path='testimonial/:id/edit' element={<EditTestimonial />} />

{/* Parent Category Route */}
<Route path='parent-category' element={<ParentCategory />} />

<Route path='parent-category/create' element={<CreateParentCategory />} />
<Route path='parent-category/:id/edit' element={<EditParentCategory />} />

{/* Category Route */}

<Route path='category' element={<Category />} />
<Route path='category/create' element={<CreateCategory />} />
<Route path='category/:id/edit' element={<EditCategory />} />


{/* CBHPM Route */}

<Route path='cbhpm' element={<Cbhpm />} />
<Route path='cbhpm/create' element={<CreateCbhpm />} />
<Route path='cbhpm/:id/edit' element={<EditCbhpm />} />


{/* Page Route */}

<Route path='page' element={<Page />} />
<Route path='page/create' element={<CreatePage />} />
<Route path='page/:id/edit' element={<EditPage />} />


    <Route path='reviews' element={<Review />} />

    <Route path='supports' element={<Support />} />

    <Route path='settings' element={<Setting />} />

    

{/* Price Management Route */}





{/* Price Management Freelance Anesthesiologist */}

<Route path='price-management-anesthesiologist-group' element={<PriceAnesthesiologistGroup />} />
<Route path='price-management-anesthesiologist-group/create' element={<CreatePriceAnesthesiologistGroup />} />
<Route path='price-management-anesthesiologist-group/:id/edit' element={<EditPriceAnesthesiologistGroup />} /> 

{/* Price Management Freelance Anesthesiologist */}

<Route path='price-management-freelance-anesthesiologist' element={<PriceAnesthesiologist />} />
<Route path='price-management-freelance-anesthesiologist/create' element={<CreatePriceAnesthesiologist />} />
<Route path='price-management-freelance-anesthesiologist/:id/edit' element={<EditPriceAnesthesiologist />} />    

{/* Price Management Pathology */}
    
<Route path='price-management-pathology' element={<PricePathology />} />
<Route path='price-management-pathology/create' element={<CreatePricePathology />} />
<Route path='price-management-pathology/:id/edit' element={<EditPricePathology />} />   
    


{/* Price Management Surgeon */}
    
<Route path='price-management-surgeon' element={<PriceSurgeon />} />
<Route path='price-management-surgeon/create' element={<CreatePriceSurgeon />} />
<Route path='price-management-surgeon/:id/edit' element={<EditPriceSurgeon />} />   
    


{/* Price Management Laboratory */}
    
<Route path='price-management-laboratory' element={<PriceLaboratory />} />
<Route path='price-management-laboratory/create' element={<CreatePriceLaboratory />} />
<Route path='price-management-laboratory/:id/edit' element={<EditPriceLaboratory />} />   


{/* Price Management image-diagnostic */}
    
<Route path='price-management-image-diagnostic' element={<PriceImageDiagnostic />} />
<Route path='price-management-image-diagnostic/create' element={<CreatePriceImageDiagnostic />} />
<Route path='price-management-image-diagnostic/:id/edit' element={<EditPriceImageDiagnostic />} />   



    </Route>
    </Routes>
    </>

  )
}
