import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';
import { useCategoryListMutation, useChangeStatusMutation, useDeleteCategoryMutation, useDeleteFaqMutation, useFaqCategoryListMutation, useFaqListMutation, useParentCategoryListMutation, useProcedureCategoryListMutation, useSelectWebsiteCategoryMutation } from '../../../services/ProjectApi';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';

export default function Category() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',procedureCategoryId:'',status:''});

    const [procedureCategoryList,setProcedureCategoryList] = useState({});

    const handleApiError = useApiErrorHandler();

    const [CategoryListMutation] = useCategoryListMutation();
    const [DeleteCategoryMutation] = useDeleteCategoryMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();

    const [loading,setLoading] = useState(false)

    const getList = async ()=>{
        try{
          setLoading(true)  
        const response = await CategoryListMutation({token,keyword:searchInput.keyword,procedureCategoryId:searchInput.procedureCategoryId,status:searchInput.status,page:currentPage});
       
        // console.log(response.error.data.statusCode)
      if(response.error){

        handleApiError(response.error)
      }
       setLoading(false)
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    }

    const [ProcedureCategoryListMutation] = useProcedureCategoryListMutation();
    const getprocedureCategory = async ()=>{
        const resp = await ProcedureCategoryListMutation({token,status:'active',page:1,limit:1000})
        if(resp.data.status){
            setProcedureCategoryList(resp.data.data);
        }
    }

    useEffect(()=>{
        
        getprocedureCategory();
        getList();
     
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);


    const deleteData = async (id) => {
        confirmAlert({
            title: 'Confirme para excluir',
            message: 'Tem certeza de que deseja excluir este item?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try {
                            const deleteResponse = await DeleteCategoryMutation({ token, id });
                            if (deleteResponse.data.status) {
                                console.log(deleteResponse.data);
                                notifySuccuess(deleteResponse.data.message);
                                getList(); // Refresh the list after deletion
                            } else {
                                notifyError(deleteResponse.data.message);
                            }
                        } catch (error) {
                            notifyError('An error occurred while deleting the item.');
                            console.error(error);
                        }
                    },
                },
                {
                    label: 'Não',
                    onClick: () => console.log('Cancelled'),
                },
            ],
        });
    };



    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const [SelectWebsiteCategoryMutation] = useSelectWebsiteCategoryMutation();

    const handleCheckboxChange = async(e,id)=>{
        let value = 0;
        if (e.target.checked) {
            value = 1;
        }

        const data = {value,id}

        const resp = await SelectWebsiteCategoryMutation({token,data});

        if(resp.data.status){
            notifySuccuess(resp.data.message)

            getList();
        }else{
            notifyError(resp.data.message)
        }
    }

  return (
    <Layout>
    <ToastContainer />
    <div className="card">
    {loading && <Loader />}

        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Lista de categorias</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/category/create`}>Adicionar categoria</Link>
                </div>
            </div>
        </div>
           <div className="card-body " style={{ overflowX: 'scroll' }}>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Pesquise aqui..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Selecione--</option>
                        <option value='active'>ativa</option>
                        <option value='Inativa'>inativa</option>
                    </select>
                </div>
                {/* <div className="col-md-3">
                    <select 
                        name='procedureCategoryId' 
                        value={searchInput.procedureCategoryId} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, procedureCategoryId: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Select Procedure Category--</option>
                        {procedureCategoryList.length > 0 && procedureCategoryList.map((value,index)=>(
                            <option key={index} value={value._id}>{value.categoryName}</option>
                        ))}
                    </select>
                </div> */}
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Selecione a categoria inicial</th>
                        <th scope="col">Título</th>
                        <th scope="col">Status</th>
                        <th scope="col">Ação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                            {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                            <td>{index + 1}</td>
                            <td><input type='checkbox' checked={value.isSelectWebsite === '1' } onChange={(e) => handleCheckboxChange(e, value._id)}  name='isChecked' value={value._id} /></td>
                            <td>{value.title}</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('Category', value._id, 'inactive') }} className='btn btn-success text-white'>Ativa</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('Category', value._id, 'active') }} className='btn btn-danger text-white'>Inativa</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/category/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>
</Layout>
  )
}
