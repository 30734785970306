import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAdminLoginMutation } from '../../services/ProjectApi'
import { toast, ToastContainer } from 'react-toastify'
import { setToken } from '../../services/Token'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../fetures/userSlice'

export default function Login() {

  const dispatch = useDispatch();

  const nofitySuccess = (message)=>{ toast.success(message)}
  const notifyError = (message)=>{toast.error(message)}


  const [inputData,setInputData] = useState({email:'',password:''});

const [errorData,setErrorData] = useState({});

  const navigate = useNavigate();

  const [adminLogin] = useAdminLoginMutation();


  const submitHandler = async (e)=>{
    e.preventDefault();
  const form_data123  = new FormData(e.currentTarget);

  const data  = {
    email:form_data123.get('email'),
    password:form_data123.get('password'),
  }

    
  const adminLoginRes = await adminLogin(data);
  // console.log(adminLoginRes)
    if(adminLoginRes.data.status){

      dispatch(setUserInfo(adminLoginRes.data.data))

      setToken(adminLoginRes.data.token)

      navigate('admin/dashboard')

    
    }else{
      if(adminLoginRes.data.errors){
        setErrorData(adminLoginRes.data.errors)
      }else{
        notifyError(adminLoginRes.data.message)
      }
    }

  }

  return (
  <>
     <main>
    <div className="container">

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                <Link to={`/`} className="logo d-flex align-items-center w-auto">
                  <img src="/assets/img/logo.svg" alt="" />
                  {/* <span className="d-none d-lg-block">QMedicina</span> */}
                </Link>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Faça login na sua conta</h5>
                    <p className="text-center small">Digite seu nome de usuário e senha para fazer login</p>
                  </div>

                  <form onSubmit={submitHandler} className="row g-3 needs-validation" novalidate>
                  <ToastContainer />

                    <div className="col-12">
                      <label for="email" className="form-label">E-mail</label>
                      <div className="input-group has-validation">
                        <input  type="text" name="email" className={`form-control ${errorData.email ? 'is-invalid':''}`}  value={inputData.email} id="email"
                        onChange={
                          (e)=>{
                             setInputData({...inputData,email:e.target.value})
                             setErrorData({...errorData,email:''})
                             }
                             }
                          />
                          {errorData.email && (
                            <div className='invalid-feedback'>{errorData.email}</div>
                          )}
                      
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="password" className="form-label">Senha</label>
                      <input type="password" name="password" className={`form-control ${errorData.password?'is-invalid':''} `} value={inputData.password} id="password" 
                      onChange={
                        (e)=>{ 
                          setInputData({...inputData,password:e.target.value})
                          setErrorData({...errorData,password:''})
                          }
                        
                        }
                       />
                     {errorData.password && (
                      <div className='invalid-feedback'>{errorData.password}</div>
                     )}
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        {/* <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" /> */}
                        {/* <label className="form-check-label" for="rememberMe">Remember me</label> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit">Conecte-se</button>
                    </div>
                    {/* <div className="col-12">
                      <p className="small mb-0">Don't have account? <Link to="pages-register.html">Create an account</Link></p>
                    </div> */}
                  </form>

                </div>
              </div>

             

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
  </>
  )
}
