import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useEditCategoryMutation, useProcedureCategoryListMutation, useProcedureDataBySubCategoryMutation, useProcedureSubCategoryListMutation, useUpdateCategoryMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { MultiSelect } from 'react-multi-select-component';
import { BASEURL } from '../../../Config';
import Loader from '../../../components/loader/Loader';

export default function EditCategory() {
    const { id } = useParams();
    const [inputData, setInputData] = useState({ procedureCategoryId: [] ,procedureSubCategoryId: [] , procedureDataId:[] });
    const [errorData, setErrorData] = useState({});
    const [procedureCategoryOption, setProcedureCategoryOption] = useState([]);
    const [procedureSubCategoryOption, setProcedureSubCategoryOption] = useState([]);
    const [procedureDataOption, setProcedureDataOption] = useState([]);

    const token = getToken();
    const handleApiError = useApiErrorHandler();
    const [UpdateCategoryMutation] = useUpdateCategoryMutation();
    const [ProcedureCategoryListMutation] = useProcedureCategoryListMutation();
    const [EditCategoryMutation] = useEditCategoryMutation();

    const [ProcedureSubCategoryListMutation] = useProcedureSubCategoryListMutation();

    const [ProcedureDataBySubCategoryMutation] = useProcedureDataBySubCategoryMutation()


    const [isCategoryDetailFetched, setIsCategoryDetailFetched] = useState(false); // New state variable

    const [imagePreview,setImagePreview] = useState('');


    // Form validation
    const validateForm = () => {
        const errors = {};
        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        if (!inputData.procedureCategoryId.length) errors.procedureCategoryId = 'O campo Categoria do grupo é obrigatório';
        if (!inputData.procedureSubCategoryId.length) errors.procedureSubCategoryId = 'O campo Subcategoria do grupo é obrigatório';
        if (!inputData.procedureDataId.length) errors.procedureDataId = 'Atleaset no campo Descrição do procedimento é obrigatório';
        console.log(errors)
        setErrorData(errors);
        return Object.keys(errors).length === 0;
    };
const navigate = useNavigate()
    // Form submission handler
    const updateHandler = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                // const data = {
                //     _id: id,
                //     title: inputData.title,
                //     procedureCategoryId: inputData.procedureCategoryId.map(option => option.value),
                //     procedureCategoryName: inputData.procedureCategoryId.map(option => option.label),
                //     procedureSubCategoryName: inputData.procedureSubCategoryId.map(option => option.label),
                //     procedureSubCategoryId: inputData.procedureSubCategoryId.map(option => option.value),
                //     procedureDataId: inputData.procedureDataId.map(option => option.value),
                //     procedureDataName: inputData.procedureDataId.map(option => option.label),
                // };

                const data = new FormData(); // Create a new FormData object

                data.append('_id', id);
                data.append('title', inputData.title);
                data.append('image', inputData.image); 
                data.append('procedureCategoryId', JSON.stringify(inputData.procedureCategoryId.map(option => option.value)));
                // data.append('procedureCategoryName', JSON.stringify(inputData.procedureCategoryId.map(option => option.label)));
                data.append('procedureSubCategoryId', JSON.stringify(inputData.procedureSubCategoryId.map(option => option.value)));
                // data.append('procedureSubCategoryName', JSON.stringify(inputData.procedureSubCategoryId.map(option => option.label)));
                data.append('procedureDataId', JSON.stringify(inputData.procedureDataId.map(option => option.value)));
                // data.append('procedureDataName', JSON.stringify(inputData.procedureDataId.map(option => option.label)));



                const response = await UpdateCategoryMutation({ id, token, data }).unwrap();
                if (response.status) {
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate("/admin/category"); 
                      }, 1000);
                } else {
                    setErrorData(response.errors || {});
                    toast.error(response.message);
                }
            } catch (error) {
                handleApiError(error);
            }
        }
    };

    // Fetch procedure categories for MultiSelect options
    const getProcedureCategory = async () => {
        try {
            setLoading(true)
            const response = await ProcedureCategoryListMutation({ token });
            if (response.data.status) {
                const formattedData = response.data.data.map(value => ({
                    'label': value.groupDescription,
                    'value': value.groupId,
                    'procedureCategoryName':value.groupDescription

                }));
                setProcedureCategoryOption(formattedData);
            }
            setLoading(false)
        } catch (error) {
            handleApiError(error);
        }
    };

        // Fetch procedure Sub categories for MultiSelect options
        const getProcedureSubCategory = async(form_data)=>{
            setLoading(true)
            const procedureCategoryId = {procedureCategoryId:form_data}
            const resp2 = await ProcedureSubCategoryListMutation({token,procedureCategoryId})
    
            const formateSubCategory =  resp2?.data?.data.map((value)=>{
                return{
                'label' : value.subgroupDescription,
                'value' : value.subgroupId,
                'ProcedureSubCategoryName' : value.subgroupDescription,
                }
            })
            setProcedureSubCategoryOption((prev) => [...prev, ...formateSubCategory]);
            setLoading(false)
        }

             // Fetch procedure Sub categories for MultiSelect options
             const getProcedureData = async (form_data) => {
                const procedureSubCategoryId = { procedureSubCategoryId: form_data };
                    setLoading(true)
                try {
                    const resp2 = await ProcedureDataBySubCategoryMutation({ token, procedureSubCategoryId });
                    
            
                    const formateData = resp2?.data?.data.map((value) => ({
                        label: value.descriptionOfTheProcedure,
                        value: value._id,
                        ProcedureSubCategoryName: value.descriptionOfTheProcedure,
                    })) || []; 
                    
                    
                    setProcedureDataOption(formateData);
                    setLoading(false)
                    
                    if (formateData.length > 0) {
                        // setInputData(prev => ({ ...prev, procedureDataId: [formateData[0]] })); // Example of setting the first procedure as selected
                    }
                    
                } catch (error) {
                    console.error("Error fetching procedure data:", error);
                }
            };
           
            const [loading,setLoading] = useState(false)

    const getCategoryDetailEdit = async () => {
        try {
            setLoading(true)
            const response = await EditCategoryMutation({ token, id }).unwrap();
         
            if (response.status) {
                const procedureCategoryId = response.data.procedureCategoryId.map((id) => {
                    const category = procedureCategoryOption.find(option => option.value === id);
                    return category || { label: '', value: id };
                });
    
                await getProcedureSubCategory(procedureCategoryId);
              
    
                const procedureSubCategoryId = response.data.procedureSubCategoryId.map(id => {
                    const subCategory = procedureSubCategoryOption.find(option => option.value === id);
                    return subCategory || { label: '', value: id };
                });

    
                await getProcedureData(procedureSubCategoryId);
    
                const procedureDataId = response.data.cbhpmId.map(id => {
                    const procedureData = procedureDataOption.find(option => option.value === id);
                    return procedureData || { label: '', value: id };
                });
                setInputData({ ...response.data, procedureCategoryId, procedureSubCategoryId, procedureDataId });
                setIsCategoryDetailFetched(true); 

                setLoading(false)

                setImagePreview(BASEURL+''+response.data.image)
            }
        } catch (error) {
            handleApiError(error);
        }
    };
    const handlerChangeProcedureCategory = (selectedOption) => {
        setInputData({ ...inputData, procedureCategoryId: selectedOption });
        setErrorData({ ...errorData, procedureCategoryId: '' });

        if(selectedOption){
            getProcedureSubCategory(selectedOption);;

            setProcedureSubCategoryOption([])
            setProcedureDataOption([])
            // setInputData((prevInputData) => ({
            //     ...prevInputData,
            //     procedureSubCategoryId: [],
            //     procedureDataId: []
            // }));
    
        }
    };
    const handlerChangeProcedureSubCategory = (selectedOption) => {
        setInputData({ ...inputData, procedureSubCategoryId: selectedOption });
        setErrorData({ ...errorData, procedureSubCategoryId: '' });

        if(selectedOption){
            getProcedureData(selectedOption);;
            // setProcedureDataOption([])
            // setInputData({...inputData,procedureDataId:[]})
        }
    };
    const handlerChangeProcedure = (selectedOption) => {
        setInputData({ ...inputData, procedureDataId: selectedOption });
        setErrorData({ ...errorData, procedureSubCategoryId: '' });
    };

    useEffect(() => {
        getProcedureCategory();
    }, []);
    useEffect(() => {
        if (procedureCategoryOption.length > 0 && !isCategoryDetailFetched) {
            getCategoryDetailEdit();
        }
    }, [procedureCategoryOption, isCategoryDetailFetched]);
    
    // useEffect(() => {
    //     if (procedureSubCategoryOption.length > 0 && !isCategoryDetailFetched) {
    //         getCategoryDetailEdit();
    //     }
    // }, [procedureSubCategoryOption, isCategoryDetailFetched]);

    useEffect(() => {
            getCategoryDetailEdit();
    }, [ isCategoryDetailFetched]);

    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6'>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Editar formulário de categoria</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/category`} className='btn btn-danger'>Voltar</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={updateHandler}>
                        <ToastContainer />
                        {loading && <Loader />}

                        <div className='form-group'>
                            <label htmlFor='title'>Título *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, title: e.target.value });
                                    setErrorData({ ...errorData, title: '' });
                                }}
                                className={`form-control ${errorData.title ? 'is-invalid' : ''}`}
                                name='title'
                                value={inputData.title || ''}
                                id='title'
                            />
                            {errorData.title && <div className='invalid-feedback'>{errorData.title}</div>}
                        </div>

                        {/* Procedure Category MultiSelect */}
                        <div className='form-group'>
                            <label htmlFor='procedureCategoryId'>Categoria de grupo *</label>
                            <MultiSelect
                                options={procedureCategoryOption}  // Available options
                                value={inputData.procedureCategoryId}  // Selected options
                                onChange={handlerChangeProcedureCategory}  // Update handler
                                labelledBy="Select Procedure Categories"
                            />
                            {/* {errorData.procedureCategoryId && <div className='invalid-feedback'>{errorData.procedureCategoryId}</div>} */}
                            {errorData.procedureCategoryId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureCategoryId}</div>
                    )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='procedureSubCategoryId'>Subcategoria de grupo *</label>
                            <MultiSelect
                                options={procedureSubCategoryOption}  // Available options
                                value={inputData.procedureSubCategoryId}  // Selected options
                                onChange={handlerChangeProcedureSubCategory}  // Update handler
                                labelledBy="Select Procedure Categories"
                            />
                            {/* {errorData.procedureSubCategoryId && <div className='invalid-feedback'>{errorData.procedureSubCategoryId}</div>} */}
                       
                            {errorData.procedureSubCategoryId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureSubCategoryId}</div>
                    )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='procedureDataId'>Descrição do procedimento *</label>
                            <MultiSelect
                                options={procedureDataOption}  // Available options
                                value={inputData.procedureDataId}  // Selected options
                                onChange={handlerChangeProcedure}  // Update handler
                                labelledBy="Select Procedure Categories"
                            />
                            {/* {errorData.procedureDataId && <div className='invalid-feedback'>{errorData.procedureDataId}</div>} */}
                            {errorData.procedureDataId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureDataId}</div>
                    )}
                        </div>

                        <div className='form-group'>
                    <label htmlFor='image'>Imagem</label>
                    <input type='file' className={`form-control ${errorData.image?'is-invalid':''}`} 
                    // onChange={(e)=>{
                    //     setInputData({...inputData,image:e.target.files[0]});
                    //     setImagePreview(URL.createObjectURL(e.target.files[0]))
                    //     setErrorData({...errorData,image:''})
                    // }} 

                              
                    onChange={(e)=>{

                            const file = e.target.files[0];

                                if (file) {
                                const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
                                if (!validTypes.includes(file.type)) {
                                    setErrorData({ ...errorData, image: 'Invalid file type. Only JPG, JPEG, PNG, or SVG are allowed.' });
                                    setInputData({ ...inputData, image: '' });
                                    setImagePreview(null);
                                }
                                else{
                                    setInputData({...inputData,image:e.target.files[0]});
                                setErrorData({...errorData,image:''})
                                setImagePreview(URL.createObjectURL(e.target.files[0]))
                                }
                            }
                            }}
                    
                    
                    name='image' id='image' />
                    {errorData.image && (
                        <div className='invalid-feedback'>{errorData.image}</div>
                    )}
                  {imagePreview && (
                    <img className='mt-3' src={imagePreview} width={100}height={100}  />
                  )}
                </div>

                        <div className='my-3'>
                            <input type='submit' className='btn btn-primary mr-3' style={{ marginRight: `5px` }} value='Enviar' />
                            <Link to={`/admin/category`} className='btn btn-danger ml-3'>Voltar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}
