import React, { useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddTestimonialMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';

export default function CreateTestimonial() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const [disableBtn,setDisableBtn] = useState(false)

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        if (!inputData.description || inputData.description.trim() === '') errors.description = 'O campo de descrição é obrigatório';
        if (!inputData.name || inputData.name.trim() === '') errors.name = 'Name field is required';
        if (!inputData.image) errors.image = 'O campo da imagem é obrigatório';

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddTestimonialMutation] = useAddTestimonialMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const navigate = useNavigate()

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    setDisableBtn(true)
    const form_data = new FormData(e.currentTarget);
    // const data = {
    //     title:form_data.get('title'),
    //     description:form_data.get('description'),
    //     name:form_data.get('name'),
    // }
 
      const AddTestimonialMutationResp = await AddTestimonialMutation({token,form_data})

      console.log(AddTestimonialMutationResp);

      if(AddTestimonialMutationResp.error){
        handleApiError(AddTestimonialMutationResp.error)
      }

      if(AddTestimonialMutationResp.data.status){
        setInputData({title:'',description:'',name:''})

        if(fileInputRef.current){
            fileInputRef.current.value = ''
        }

        setImagePreview('')

        nofitySuccess(AddTestimonialMutationResp.data.message)

        setTimeout(() => {
            navigate("/admin/testimonials"); 
          }, 1000);
        
      }else{
        if(AddTestimonialMutationResp.data.errors){
            setErrorData(AddTestimonialMutationResp.data.errors)
        }
        notifyError(AddTestimonialMutationResp.data.message);
      }

      setDisableBtn(false)


   }
}

  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Adicionar formulário de depoimento</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/testimonials`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
                   <label htmlFor='title'>Título *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,title:e.target.value})
                        setErrorData({...errorData,title:''})
                    }} className={`form-control ${errorData.title ? 'is-invalid':''}`} name='title' value={inputData.title} id='title' />
                {errorData.title && (
                    <div className='invalid-feedback'>{errorData.title}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='description'>Descrição</label>
                    <textarea className={`form-control ${errorData.description ?'is-invalid':''}`} name='description' onChange={(e)=>{
                        setInputData({...inputData,description:e.target.value})
                        setErrorData({...errorData,description:''})
                    }} id='description' value={inputData.description} ></textarea>
                    {errorData.description && (
                        <div className='invalid-feedback'>{errorData.description}</div>
                    )}
                </div>

                <div className='form-group'>
                    <label htmlFor='name'>Nome do cliente *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,name:e.target.value})
                        setErrorData({...errorData,name:''})
                    }} className={`form-control ${errorData.name ? 'is-invalid':''}`} name='name' value={inputData.name} id='name' />
                {errorData.name && (
                    <div className='invalid-feedback'>{errorData.name}</div>
                )}
                </div>

               

                <div className='form-group'>
                    <label htmlFor='image'>Imagem</label>
                    <input type='file' className={`form-control ${errorData.image?'is-invalid':''}`} onChange={(e)=>{
                        setInputData({...inputData,image:e.target.files[0]});
                        setImagePreview(URL.createObjectURL(e.target.files[0]))
                        setErrorData({...errorData,image:''})
                    }} name='image' id='image' />
                    {errorData.image && (
                        <div className='invalid-feedback'>{errorData.image}</div>
                    )}
                  {imagePreview && (
                    <img src={imagePreview} width={100}height={100}  />
                  )}
                </div>


             

                <div className=' my-3'>
                <input type='submit' disabled={disableBtn} className='btn btn-primary mr-3' style={{ marginRight:`5px` }}value={`Enviar`} />
                <Link to={`/admin/testimonials`} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
