import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddTestimonialMutation, useEditPatientMutation, useEditTestimonialMutation, useUpdatePatientMutation, useUpdateTestimonialMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { BASEURL } from '../../../Config';
import { DatePicker } from 'rsuite';
import { format } from 'date-fns';

export default function EditPatient() {

const {id} = useParams()

const [EditPatientMutation] =  useEditPatientMutation();

const viewDetail = async(id)=>{
  const editResponse =   await EditPatientMutation({token,id})

  if(editResponse.data.status){
    setInputData(editResponse.data.data)

    setImagePreview(BASEURL+''+editResponse.data.data.image)
  }
}

useEffect(()=>{
    viewDetail(id);
},[])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.firstName || inputData.firstName.trim() === '') errors.firstName = 'O campo Nome é obrigatório';
        if (!inputData.lastName || inputData.lastName.trim() === '') errors.lastName = 'O campo Sobrenome é obrigatório';
        if (!inputData.email || inputData.email.trim() === '') errors.email = 'O campo e-mail é obrigatório';
        if (!inputData.dob || inputData.dob.trim() === '') errors.dob = 'O campo Data de Nascimento é obrigatório';
       

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [UpdatePatientMutation] = useUpdatePatientMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const navigate = useNavigate()

const updateHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);

    form_data.append('_id',id)
    form_data.append('_method','PUT')

    // const data = {
    //     _mothod:'PUT',
    //     firstName:form_data.get('firstName'),
    //     lastName:form_data.get('lastName'),
    //     email:form_data.get('email'),
    //     dob:inputData.dob,
    // }
    form_data.append('dob',inputData.dob)

      const updateResponse = await UpdatePatientMutation({id,token,form_data})

      console.log(updateResponse);

      if(updateResponse.error){
        handleApiError(updateResponse.error)
      }

      if(updateResponse.data.status){
        // setInputData({title:'',description:'',name:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        // setImagePreview('')

        nofitySuccess(updateResponse.data.message)
        setTimeout(() => {
            navigate("/admin/patients"); 
          }, 1000);
      }else{
        if(updateResponse.data.errors){
            setErrorData(updateResponse.data.errors)
        }
        notifyError(updateResponse.data.message);
      }

   }
}



  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Editar formulário do paciente</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/patients`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={updateHandler}>
            <ToastContainer />


      
                <div className='form-group'>
                    <label htmlFor='firstName'>Primeiro nome *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,firstName:e.target.value})
                        setErrorData({...errorData,firstName:''})
                    }} className={`form-control ${errorData.firstName ? 'is-invalid':''}`} name='firstName' value={inputData.firstName} id='firstName' />
                {errorData.firstName && (
                    <div className='invalid-feedback'>{errorData.firstName}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='lastName'>Sobrenome *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,lastName:e.target.value})
                        setErrorData({...errorData,lastName:''})
                    }} className={`form-control ${errorData.lastName ? 'is-invalid':''}`} name='lastName' value={inputData.lastName} id='lastName' />
                {errorData.lastName && (
                    <div className='invalid-feedback'>{errorData.lastName}</div>
                )}
                </div>

               
                <div className='form-group'>
                    <label htmlFor='email'>E-mail *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,email:e.target.value})
                        setErrorData({...errorData,email:''})
                    }} className={`form-control ${errorData.email ? 'is-invalid':''}`} name='email' value={inputData.email} id='email' />
                {errorData.email && (
                    <div className='invalid-feedback'>{errorData.email}</div>
                )}
                </div>

                <div className='form-group'>
    <label htmlFor='dob'>Data de nascimento *</label>
    <div className="date-group">
        <DatePicker
            oneTap
            value={inputData.dob ? new Date(inputData.dob) : null}
            onChange={(date) => {
                if (date) {
                    const formattedDate = format(date, 'yyyy-MM-dd');
                    console.log('Selected Date:', date);
                   // console.log('Formatted Date:', formattedDate);
                    setInputData({ ...inputData, dob: formattedDate });
                } else {
                    setInputData({ ...inputData, dob: null });
                }
                setErrorData({ ...errorData, dob: '' });
            }}
            format='yyyy-MM-dd'
            className={`form-control ${errorData.dob ? 'is-invalid' : ''}`}
            name='dob'
            id='dob'
            placeholder="Select Date of Birth"
        />
    </div>
    {errorData.dob && (
        <div className='invalid-feedback d-block'>{errorData.dob}</div>
    )}
</div>



                {/* <div className='form-group'>
                    <label htmlFor='password'>Password *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,password:e.target.value})
                        setErrorData({...errorData,password:''})
                    }} className={`form-control ${errorData.password ? 'is-invalid':''}`} name='password' value={inputData.password} id='password' />
                {errorData.password && (
                    <div className='invalid-feedback'>{errorData.password}</div>
                )}
                </div>


                <div className='form-group'>
                    <label htmlFor='confirmPassword'>Confirm Password *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,confirmPassword:e.target.value})
                        setErrorData({...errorData,confirmPassword:''})
                    }} className={`form-control ${errorData.confirmPassword ? 'is-invalid':''}`} name='confirmPassword' value={inputData.confirmPassword} id='confirmPassword' />
                {errorData.confirmPassword && (
                    <div className='invalid-feedback'>{errorData.confirmPassword}</div>
                )}
                </div> */}


             

                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Enviar`} />
                <Link to={`/admin/patients`} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
