import React from 'react'
import { getToken } from '../services/Token';
import { Navigate, Outlet } from 'react-router-dom';

export default function PublicRoute() {
  const token = getToken();

  return (
    <>{!token ? <Outlet />:<Navigate to={`/admin/dashboard`} />}</>
  
  )
}
