import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddCategoryMutation, useAddFaqMutation, useAddParentCategoryMutation, useFaqCategoryListMutation, useParentCategoryListMutation, useProcedureCategoryListMutation, useProcedureDataBySubCategoryMutation, useProcedureSubCategoryListMutation, useUploadImageMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';
import { MultiSelect } from 'react-multi-select-component';

export default function CreateCategory() {

      const {MyCustomUploadAdapterPlugin} = useCkeditorUploadAdapter();

    const nofitySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const [inputData, setInputData] = useState({ procedureCategoryId: [] , procedureSubCategoryId:[],procedureDataId:[]});
    const [errorData, setErrorData] = useState({});
    const [parentCategoryList, setParentCategoryList] = useState([]);

    const [procedureCategoryOption ,setProcedureCategoryOption] = useState([])

    const [procedureSubCategoryOption ,setProcedureSubCategoryOption] = useState([])

    const [procedureDataOption ,setProcedureDAtaOption] = useState([])

    const fileInputRef  = useRef(null);

    const [imagePreview,setImagePreview] = useState('');


    const validateForm = () => {
        const errors = {};
        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        // if (!inputData.parentCategoryId || inputData.parentCategoryId.trim() === '') errors.parentCategoryId = 'Parent Category Id field is required';
      
        if (!inputData.procedureCategoryId.length) errors.procedureCategoryId = 'Pelo menos uma categoria de grupo é obrigatória';
        if (!inputData.procedureSubCategoryId.length) errors.procedureSubCategoryId = 'É necessária pelo menos uma subcategoria de grupo';


        if (!inputData.procedureSubCategoryId || inputData.procedureSubCategoryId === "")
            errors.procedureSubCategoryId = "É necessária pelo menos uma subcategoria de grupo";



        if(!inputData.procedureDataId.length) errors.procedureDataId = 'Pelo menos na Descrição do Procedimento é necessária'

        if (!inputData.image) errors.image = 'O campo da imagem é obrigatório';

        console.log(errors)


        setErrorData(errors);
        return Object.keys(errors).length === 0;
    }

    const [AddCategoryMutation] = useAddCategoryMutation();
    const token = getToken();
    const handleApiError = useApiErrorHandler();

    const navigate = useNavigate();

    const [disableBtn,setDisableBtn] = useState(false)


    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setDisableBtn(true)
            // const data = {
            //     title: inputData.title,
            //     // answer: inputData.answer,
            //     // parentCategoryId: inputData.parentCategoryId,


            //     procedureCategoryId: inputData.procedureCategoryId.map(option=>option.value),
            //     // procedureCategoryName:inputData.procedureCategoryId.map(option=>option.label),
            //     procedureSubCategoryId: inputData.procedureSubCategoryId.map(option=>option.value),
            //     // procedureSubCategoryName: inputData.procedureSubCategoryId.map(option=>option.label),
           
            //     procedureDataId :inputData.procedureDataId.map((option)=>option.value),
            //     // procedureDataName :inputData.procedureDataId.map((option)=>option.label)
           
            // };

            const data = new FormData(); // Create a new FormData object

            data.append('title', inputData.title);
            data.append('image', inputData.image); 
            data.append('procedureCategoryId', JSON.stringify(inputData.procedureCategoryId.map(option => option.value)));
            // data.append('procedureCategoryName', JSON.stringify(inputData.procedureCategoryId.map(option => option.label)));
            data.append('procedureSubCategoryId', JSON.stringify(inputData.procedureSubCategoryId.map(option => option.value)));
            // data.append('procedureSubCategoryName', JSON.stringify(inputData.procedureSubCategoryId.map(option => option.label)));
            data.append('procedureDataId', JSON.stringify(inputData.procedureDataId.map(option => option.value)));
            // data.append('procedureDataName', JSON.stringify(inputData.procedureDataId.map(option => option.label)));

            const response = await AddCategoryMutation({ token, data });

            if (response.error) {
                handleApiError(response.error);
            }

            if (response.data.status) {
                setInputData({ title: '', procedureCategoryId: [] ,procedureSubCategoryId:[] , procedureDataId:[]  });
              
                if(fileInputRef.current){
                    fileInputRef.current.value = ''
                }
        
                setImagePreview('')
              
                nofitySuccess(response.data.message);
                setTimeout(() => {
                    navigate("/admin/category"); 
                  }, 1000);
            } else {
                if (response.data.errors) {
                    setErrorData(response.data.errors);
                }
                notifyError(response.data.message);
            }
            setDisableBtn(false)
           
        }
    }

    const [ProcedureCategoryListMutation] = useProcedureCategoryListMutation();

    const getProcedureCategoryList = async () => {
        const resp = await ProcedureCategoryListMutation({ token, status: 'active' });
        if (resp.data.status) {
            console.log(resp)
            const formateData = resp.data.data.map(value=>{

                return{
                    'label':value.groupDescription,
                    'value':value.groupId,
                    'procedureCategoryName':value.groupDescription
                }
            })


            setProcedureCategoryOption(formateData);
        }
    }

    useEffect(() => {
        getProcedureCategoryList();
    }, []);

 

   const [ProcedureSubCategoryListMutation] = useProcedureSubCategoryListMutation();
    const getProcedureSubCategory = async(form_data)=>{
        const procedureCategoryId = {procedureCategoryId:form_data}
        const resp2 = await ProcedureSubCategoryListMutation({token,procedureCategoryId})
        // console.log(resp2)

        const formateSubCategory =  resp2?.data?.data.map((value)=>{
            return{
            'label' : value.subgroupDescription,
            'value' : value.subgroupId,
            'ProcedureSubCategoryName' : value.subgroupDescription,
            }
        })

        setProcedureSubCategoryOption(formateSubCategory)
    }

    const [ProcedureDataBySubCategoryMutation] = useProcedureDataBySubCategoryMutation();

    const getProcedureData = async(form_data)=>{
        const procedureSubCategoryId = {procedureSubCategoryId:form_data}
        const resp2 = await ProcedureDataBySubCategoryMutation({token,procedureSubCategoryId})


        const formatProcedureData =  resp2?.data?.data.map((value)=>{
            return{
            'label' : value.descriptionOfTheProcedure,
            'value' : value._id,
            'descriptionOfTheProcedure' : value.descriptionOfTheProcedure,
            }
        })

        setProcedureDAtaOption(formatProcedureData)
    }

    // console.log(procedureDataOption)


const handleChangeProcedureCategory =  (selectedOption)=>{
    setInputData({...inputData,procedureCategoryId:selectedOption})
    setErrorData({ ...errorData, procedureCategoryId: '' });

    
    // if(selectedOption){
    //     getProcedureSubCategory(selectedOption);;

    // }

    if (selectedOption?.length) {
        getProcedureSubCategory(selectedOption);
      } else {
        // Clear subcategories if no categories are selected
        setProcedureSubCategoryOption([]);
      }

}

const handleChangeProcedureSubCategory =  (selectedOption)=>{
    setInputData({...inputData,procedureSubCategoryId:selectedOption})
    setErrorData({ ...errorData, procedureSubCategoryId: '' });
    // console.log(selectedOption)
    if(selectedOption){
        getProcedureData(selectedOption);;

    }
}

const handleChangeProcedureData =  (selectedOption)=>{
    setInputData({...inputData,procedureDataId:selectedOption})
    setErrorData({ ...errorData, procedureDataId: '' });
    console.log(selectedOption)
    if(selectedOption){
        // getProcedureData(selectedOption);;

    }
}



 
    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6 '>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Adicionar formulário de categoria</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/category`} className='btn btn-danger'>Voltar</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={submitHandler}>
                        <ToastContainer />

                        <div className='form-group'>
                            <label htmlFor='title'>Título *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({...inputData,title:e.target.value});
                                    setErrorData({...errorData,title:''});
                                }}
                                className={`form-control ${errorData.title ? 'is-invalid' : ''}`}
                                name='title'
                                value={inputData.title}
                                id='title'
                            />
                            {errorData.title && (
                                <div className='invalid-feedback'>{errorData.title}</div>
                            )}
                        </div>

                    

                        {/* <div className='form-group'>
                            <label htmlFor='parentCategoryId'>Parent Category Id*</label>
                            <select
                                name='parentCategoryId'
                                id='parentCategoryId'
                                className={`form-control ${errorData.parentCategoryId ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  
                                    setInputData({...inputData,parentCategoryId:e.target.value});
                                    setErrorData({...errorData,parentCategoryId:''});
                               
                                }}
                                value={inputData.parentCategoryId}
                            >
                                <option value="">--Selecione--</option>
                                {parentCategoryList.length > 0 && parentCategoryList.map((value, index) => (
                                    <option key={index} value={value._id}>{value.title}</option>
                                ))}
                            </select>
                            {errorData.parentCategoryId && (
                                <div className='invalid-feedback'>{errorData.parentCategoryId}</div>
                            )}
                        </div> */}


                        <div className='form-group'>
                  <label for="procedureCategory" class="col-sm-2 col-form-label">Categoria de grupo</label>
                  <div class="col-sm-12">
                    <MultiSelect
                    options={procedureCategoryOption}
                    value={inputData.procedureCategoryId}
                    onChange={(selectedProcedureCategory)=>{handleChangeProcedureCategory(selectedProcedureCategory) }}
                      labelledBy="Select Procedure Categories"
                    />
                    {errorData.procedureCategoryId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureCategoryId}</div>
                    )}


                    
                  </div>
                </div>

                <div className='form-group'>
                  <label for="procedureSubCategory" class="col-sm-2 col-form-label">Subcategoria de grupo</label>
                  <div class="col-sm-12">
                    <MultiSelect
                    options={procedureSubCategoryOption}
                    value={inputData.procedureSubCategoryId}
                    onChange={(selectedProcedureSubCategory)=>{handleChangeProcedureSubCategory(selectedProcedureSubCategory) }}
                      labelledBy="Select Procedure Categories"
                    />
                    {errorData.procedureSubCategoryId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureSubCategoryId}</div>
                    )}
                  </div>
                </div>

                

                <div className='form-group'>
                  <label for="procedureData" class="col-sm-2 col-form-label">Descrição do procedimento</label>
                  <div class="col-sm-12">
                    <MultiSelect
                    options={procedureDataOption}
                    value={inputData.procedureDataId}
                    onChange={(selectedProcedureData)=>{handleChangeProcedureData(selectedProcedureData) }}
                      labelledBy="Select Procedure Categories"
                    />
                    {errorData.procedureDataId && (
                      <div className='error'  style={{ color: "red" }}>{errorData.procedureDataId}</div>
                    )}
                  </div>
                </div>


                <div className='form-group'>
                    <label htmlFor='image'>Imagem</label>
                    <input type='file' className={`form-control ${errorData.image?'is-invalid':''}`} 
                    // onChange={(e)=>{
                    //     setInputData({...inputData,image:e.target.files[0]});
                    //     setImagePreview(URL.createObjectURL(e.target.files[0]))
                    //     setErrorData({...errorData,image:''})
                    // }} 
                    
                    
                             onChange={(e)=>{

                            const file = e.target.files[0];

                                if (file) {
                                const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
                                if (!validTypes.includes(file.type)) {
                                    setErrorData({ ...errorData, image: 'Invalid file type. Only JPG, JPEG, PNG, or SVG are allowed.' });
                                    setInputData({ ...inputData, image: '' });
                                    setImagePreview(null);
                                
                                
                                }

                                else{
                                    setInputData({...inputData,image:e.target.files[0]});
                                setErrorData({...errorData,image:''})
                                setImagePreview(URL.createObjectURL(e.target.files[0]))

                                }

}

}}
                    
                    
                    
                    name='image' id='image' />
                    {errorData.image && (
                        <div className='invalid-feedback'>{errorData.image}</div>
                    )}
                  {imagePreview && (
                    <img src={imagePreview} width={100}height={100}  />
                  )}
                </div>




                        <div className='my-3'>
                            <input type='submit' disabled={disableBtn} className='btn btn-primary mr-3' style={{ marginRight: `5px` }} value={`Enviar`} />
                            <Link to={`/admin/category`} className='btn btn-danger ml-3'>Voltar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}
