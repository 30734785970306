import React, { useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom'
import { useAdminChangePasswordMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { toast, ToastContainer } from 'react-toastify';

export default function ChangePassword() {

    const nofitySuccess = (message)=>toast.success(message);
    const notifyError = (message)=>toast.error(message);

    const [AdminChangePassword] = useAdminChangePasswordMutation();
    const token = getToken();

    const [errorData,setErrorData] = useState({});

    const [inputData,setInputData]  = useState({});

    const handleApiErro = useApiErrorHandler();

    const submitHandler = async(e)=>{
        e.preventDefault();

        const data  = new FormData(e.currentTarget);

        const form_data = {
           old_password: data.get('old_password'),
           new_password: data.get('new_password'),
           confirm_password: data.get('confirm_password')
        }
        try{

        const AdminChangePasswordResp = await AdminChangePassword({token,form_data})

        console.log(AdminChangePasswordResp)
        if(AdminChangePasswordResp.error){
            handleApiErro(AdminChangePasswordResp.error)
        }

        if(AdminChangePasswordResp.data.status){
            nofitySuccess(AdminChangePasswordResp.data.message)
            setInputData({ old_password: '', new_password: '', confirm_password: '' });
        }else{
            if(AdminChangePasswordResp.data.errors){
                setErrorData(AdminChangePasswordResp.data.errors)
            }
            else{
                notifyError(AdminChangePasswordResp.data.message)
            }
        }
    } catch (error) {
        console.error('An unexpected error occurred:', error);
    }

    }
  return (
   <Layout>
   <ToastContainer />
    <div className='card'>
        <div className='card-header'>
        <div className='row'>
              <div className='col-md-6'>
              <h5 class="card-title">Alterar a senha</h5>
              </div>
              <div className='col-md-6 text-end mt-3'>
                <Link to={`/admin/users`} className='btn btn-danger '>Voltar</Link>
              </div>
            </div>
        </div>
        <div className='card-body'>
            <form onSubmit={submitHandler}>
                <div className='form-group'>
                <label htmlFor='old_password'>Senha Antiga</label>
                    <input type='password' onChange={(e)=>{
                         setInputData({...inputData,old_password:e.target.value})
                         setErrorData({...errorData,old_password:''})
                         }}  value={inputData.old_password} class={`form-control ${errorData.old_password ? 'is-invalid':''}`} id='old_password' name='old_password' />
               {errorData.old_password && (
                <div className='invalid-feedback'>{errorData.old_password} </div>
               )}
                </div>

                <div className='form-group'>
                <label htmlFor='new_password'>Nova Senha</label>
                    <input type='password' onChange={(e)=>{ 
                        setInputData({...inputData,new_password:e.target.value})
                        setErrorData({...errorData,new_password:''})
                        }}  value={inputData.new_password} class={`form-control  ${errorData.new_password ? 'is-invalid':''}`} id='new_password' name='new_password' />
                    {errorData.new_password && (
                <div className='invalid-feedback'>{errorData.new_password} </div>
               )}
                </div>

                <div className='form-group'>
                <label htmlFor='confirm_password'>Confirme sua senha</label>
                    <input type='password' onChange={(e)=>{ 
                        setInputData({...inputData,confirm_password:e.target.value})
                        setErrorData({...errorData,confirm_password:''})
                        }}  value={inputData.confirm_password } class={`form-control  ${errorData.confirm_password ? 'is-invalid':''}`} id='confirm_password' name='confirm_password' />
                    {errorData.confirm_password && (
                <div className='invalid-feedback'>{errorData.confirm_password} </div>
               )}
                </div>
                <div className=''>
                    <input type='submit' className='btn btn-primary mt-3' value={`Atualizar`} />
                </div>
            </form>
        </div>
        <div className='card-footer'></div>
    </div>
   </Layout>
  )
}
