import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link, useLocation } from 'react-router-dom';
import {  useChangeStatusMutation, useDeleteBlogMutation, useHealthcareInstituteListMutation, useResetPasswordProviderMutation, useUpdatePermissionhealthcareInstitutionMutation  } from '../../../services/ProjectApi';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';

export default function HealthcareInstitutions() {

    const location = useLocation();

    const currentPath = location.pathname.split('/');

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',faqCategoryId:'',status:''});


    const handleApiError = useApiErrorHandler();

    const [HealthcareInstituteListMutation] = useHealthcareInstituteListMutation();
    const [DeleteBlogMutation] = useDeleteBlogMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();

    let providerType = currentPath[2]

  


    const getList = async ()=>{
        setLoading(true)
        try{
        const response = await HealthcareInstituteListMutation({token,providerType,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
      
      if(response.error){
        handleApiError(response.error)
      }
       
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    setLoading(false)
    }

    useEffect(()=>{
        
        getList();
     
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);

    useEffect(()=>{
        
        getList();
     
    },[location.pathname]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            const DeleteBlogMutationResp = await DeleteBlogMutation({token,id});
            console.log(DeleteBlogMutationResp)
            if(DeleteBlogMutationResp.data.status){
                notifySuccuess(DeleteBlogMutationResp.data.message);
                getList();
            } else {
                notifyError(DeleteBlogMutationResp.data.message);
            }
        }
    }
    const [ResetPasswordProviderMutation] = useResetPasswordProviderMutation();

   
const [loading,setLoading] = useState(false)

    const resetPasswordLink = async (id) => {
        confirmAlert({
            title: 'Confirmar ',
            message: 'Tem certeza de que deseja enviar e-mail para ativar este provedor?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try {
                            setLoading(true)
                            const sendMailResponse = await ResetPasswordProviderMutation({ token, id });
                            setLoading(false)
                            if (sendMailResponse.data.status) {
                                console.log(sendMailResponse.data);
                                notifySuccuess(sendMailResponse.data.message);
                                getList(); // Refresh the list after deletion
                            } else {
                                notifyError(sendMailResponse.data.message);
                            }
                        } catch (error) {
                            notifyError('An error occurred while deleting the item.');
                            console.error(error);
                        }
                    },
                },
                {
                    label: 'Não',
                    onClick: () => console.log('Cancelled'),
                },
            ],
        });
    };


    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        console.log(ChangeStatusMutationResp)
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const [checkRow,setCheckRow] = useState([]);

    const [isSelectAllChecked,setIsSelectAllChecked] = useState(false)



    const handleCheckbox = async(e,providerId)=>{
        // alert(providerId)

        if(e.target.checked){
            setCheckRow([...checkRow,providerId])
        }else{
            setCheckRow(checkRow.filter(i=>i!== providerId))
        }


    }




    const handleAllCheckBox = async(e)=>{
        if(e.target.checked){
            const allRowsIds = list.map((value)=>value._id)
            setCheckRow(allRowsIds)
            setIsSelectAllChecked(true)
        }else{
           setCheckRow([])
           setIsSelectAllChecked(false)
        }
    }

    useEffect(()=>{
        console.log(checkRow)
    },[handleCheckbox,handleAllCheckBox])

    const [showModel,setShowModel] = useState(false)

    const permissionHandler = async()=>{
        setShowModel(true)
    }

    const handleClose = ()=>{
        setShowModel(false)
    }


    const [permissions, setPermissions] = useState({
        operatingRoomHourlyRate: "false",
        recoveryRoomHourlyRate: "false",
        sharedRoomDailyRate: "false",
        privateRoomDailyRate: "false",
          icuDailyRate: "false",
         managePricingPermmision: "false",    
         nonNeoplasticPrice: "false",
         neoplasticPrice: "false",
         operativeFrozenPrice: "false",
         ucoValue: "false",
         imageCost: "false",

      });

      const [UpdatePermissionhealthcareInstitutionMutation] = useUpdatePermissionhealthcareInstitutionMutation();

const handelGivePermission = async(e,permissionKey)=>{
    const { checked } = e.target;

    const updatedPermissions = {
        ...permissions,
        [permissionKey]: checked ? 'true' : 'false',
      };


     setPermissions(updatedPermissions)


     const form_data = {
        _method: 'PUT',
        permission: updatedPermissions,
      };
      console.log(checkRow)
    const updateResponse =   checkRow.map((id)=>(
         UpdatePermissionhealthcareInstitutionMutation({ id, token, form_data })
      ))

      const resp = await Promise.all(updateResponse)

    //   console.log(resp[0].data);

      if(resp[0].data.status){
       notifySuccuess(resp[0].data.message)
      }

}



  return (
    <Layout>
    <ToastContainer />
    {loading && <Loader />}
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Lista de Instituições de Saúde</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    {/* <Link className="btn btn-primary" to={`/admin/blog/create`}>Add Blog</Link> */}
                </div>
            </div>
        </div>
        <div className="card-body " style={{ overflowX: 'scroll' }}>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Pesquise aqui..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Selecione--</option>
                        <option value='active'>Ativa</option>
                        <option value='inactive'>Inativa</option>
                    </select>
                </div>
                 {checkRow.length > 0 && (
                    <>
                    <div className='col-md-3'>
                   <button onClick={permissionHandler} className='btn btn-danger' >Modificar permissões</button>
                </div>
                    </>
                 )}       
                
               
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th><input type='checkbox' checked={isSelectAllChecked} onChange={handleAllCheckBox} /></th>
                        {/* <th scope="col">Imagem</th> */}
                        <th scope="col">Tipo</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Nome Fantasia da Empresa</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        {( providerType === 'laboratory' || providerType === 'imaging-diagnostic-clinic' ) && (
                            <>
                            <th scope="col">Avaliação</th>

                            </>
                        )}
                        <th scope="col">Data de criação</th>
                        <th scope="col">Status</th>
                        <th scope="col">Ação</th>
                        {/* <th scope="col">Ação</th> */}
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                            {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                            <td>{index + 1}</td>
                            <td><input type='checkbox' checked={checkRow.includes(value._id)}  onChange={(e)=>{ handleCheckbox(e,value._id) }} /></td>
                            {/* <td><img src={`${BASEURL}${value.image}`} width="50px" height="50px" alt="Blog" /></td> */}
                            <td>{  value.type }</td>
                            <td>{  value.companyName }</td>
                            <td>{  value.companyFantasyName }</td>
                            <td>{  value.email }</td>
                            <td>{  value.phone }</td>
                            {(providerType === 'laboratory' || providerType === 'imaging-diagnostic-clinic') && (
                                <>
                            <td>{  value.totalRating || 0 } ({value.totalSurgeory})</td>
                            </>
                            )}
                           <td>{format(new Date(value.created_at || Date.now()), 'dd-MM-yyyy')}</td>

                             <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('HealthcareInstitution', value._id, 'inactive') }} className='btn btn-success text-white'>Ativa</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('HealthcareInstitution', value._id, 'active') }} className='btn btn-danger text-white'>Inativa</Link>
                            )}</td>
                            <td>
                                {/* <Link to={`/admin/blog/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link> */}
                          
                              <div className='d-flex'>
                                <Link title='Reset Password' onClick={()=>{ resetPasswordLink(value._id) }} className='btn btn-warning btn-sm mx-1'><i className="bi bi-envelope"></i></Link>
                                <Link title='View Detail' to={`/admin/healthcare-institutions/${value._id}`} className='btn btn-primary btn-sm mx-1'><i className="bi bi-eye"></i></Link>
                                </div>
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>

    <Modal show={showModel} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lista de permissões</Modal.Title>            
      </Modal.Header>  
      <Modal.Body>
      {providerType === 'anesthesia-group' &&(
        <>
            <ul style={{ listStyle:'none' }}>
            {
                [
                    {key:'managePricingPermmision',label:'Gerenciar permissão de preços'}
                ].map((value,index)=>(
                    <>
                    <li key={index}>
                       <input onChange={(e)=>{ handelGivePermission(e,value.key) }} type='checkbox'  /><span>{value.label}</span>
                    </li>
                    </>
                ))
            }
                        </ul>
        </>
      )}


      {providerType === 'hospital' && (
        <>
        <ul style={{ listStyle:'none' }}>
            {
                [
                        { key: 'operatingRoomHourlyRate', label: 'Taxa horária da sala cirúrgica' },
                        { key: 'recoveryRoomHourlyRate', label: 'Taxa horária da sala de recuperação' },
                        { key: 'sharedRoomDailyRate', label: 'Diária Quarto Compartilhado' },
                        { key: 'privateRoomDailyRate', label: 'Diária Quarto Privado' },
                        { key: 'icuDailyRate', label: 'Diária UTI' },
                ].map((value,index)=>(
                    <>
                    <li key={index}>
                     <input type='checkbox' onChange={(e)=> handelGivePermission(e,value.key)} /><span>{value.label}</span>
                    </li>
                    </>
                ))
            }
        </ul>
        </>
      )}

      {providerType === 'imaging-diagnostic-clinic' && (
        <>
        <ul style={{ listStyle:'none' }}>
            {
                [
                        { key: 'ucoValue', label: 'Taxa de Valor do OAU' },
                        { key: 'imageCost', label: 'Custo da imagem' },
                        { key: 'managePricingPermmision', label: 'Gerenciar permissão de preços' },
                ].map((value,index)=>(
                    <>
                    <li key={index}>
                     <input type='checkbox' onChange={(e)=> handelGivePermission(e,value.key)} /><span>{value.label}</span>
                    </li>
                    </>
                ))
            }
        </ul>
        </>
      )}


      {providerType === 'laboratory' && (
        <>
        <ul style={{ listStyle:'none' }}>
            {
                [
                    { key: 'ucoValue', label: 'Taxa de Valor do OAU' },
                    { key: 'managePricingPermmision', label: 'Gerenciar permissão de preços' },
                ].map((value,index)=>(
                    <>
                    <li key={index}>
                     <input type='checkbox' onChange={(e)=> handelGivePermission(e,value.key)} /><span>{value.label}</span>
                    </li>
                    </>
                ))
            }
        </ul>
        </>
      )}

      {providerType === 'pathology-clinic' && (
        <>
        <ul style={{ listStyle:'none' }}>
            {
                [
                        { key: 'nonNeoplasticPrice', label: 'Preço de plástico não Neo' },
                        { key: 'neoplasticPrice', label: 'Preço do Neo Plástico' },
                        { key: 'operativeFrozenPrice', label: 'Preço Operativo Congelado' },
                ].map((value,index)=>(
                    <>
                    <li key={index}>
                     <input type='checkbox' onChange={(e)=> handelGivePermission(e,value.key)} /><span>{value.label}</span>
                    </li>
                    </>
                ))
            }
        </ul>
        </>
      )}

      </Modal.Body>            
      <Modal.Footer>
        <Button  variant='secondary' onClick={handleClose} >Fechar</Button>
      </Modal.Footer>
    </Modal>
</Layout>
  )
}
