export const  formDataToJson = (formData)=> {
    const object = {};
    formData.forEach((value, key) => {
        // Check if the key already exists
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            // If it's not an array, convert it to an array
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        } else {
            object[key] = value;
        }
    });
    return object;


}


export const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    // Get date parts
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [monthDayYear, time] = formattedDate.split(', ');

    // Ensure the time is in 12-hour format with AM/PM
    const hours = date.getHours();
    const period = hours >= 12 ? 'PM' : 'AM';
    const timeWithoutPeriod = time.replace(/ AM| PM/, '');

    return `${monthDayYear}. ${timeWithoutPeriod} ${period}`;
};




export const handleApiResponse = (response, setErrorData, notifySuccess, notifyError) => {
    try {
        if (response && response.data && response.data.status) {
            notifySuccess(response.data.message);
        } else if (response && response.data && response.data.errors) {
            setErrorData(response.data.errors);
        } else {
            notifyError("An unexpected error occurred. Please try again.");
        }
    } catch (error) {
        if (error.response) {
            // Handling validation error (400)
            if (error.response.status === 400) {
                console.log('Validation error detected.');
                alert('Validation error');
                const errors = error.response.data.errors;
                if (errors) {
                    setErrorData(errors);
                } else {
                    notifyError(error.response.data.message || "Validation error occurred.");
                }
            }
            // Handling server error (500)
            else if (error.response.status === 500) {
                notifyError("Server error occurred. Please try again later.");
            }
            // Handling any other errors
            else {
                notifyError("An unexpected error occurred.");
            }
        } else {
            notifyError("Network error. Please check your connection.");
        }
    }
};
