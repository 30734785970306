import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import {  Link } from 'react-router-dom';
import { useCbhpmListMutation, useChangeStatusMutation, useDeleteCbhpmMutation, useDeleteFaqCategoryMutation, useFaqCategoryListMutation, useUpdateCbhpmAssignRoleMutation, useUpdateMultipleStatusMutation, useUploadCbhpmFileMutation } from '../../../services/ProjectApi';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';
import { Button } from 'react-bootstrap';

import Select from "react-select";
import { MultiSelect } from 'react-multi-select-component';

export default function Cbhpm() {

    const [options, setOptions] = useState([
        {  value: `Surgeon`,label: `Surgeon`},
        {  value: `GeneralPractitioner`,label: `GeneralPractitioner`},
        {  value: `SpecialistClinician`,label: `SpecialistClinician`},
        {  value: `OtherHealthProfessional`,label: `OtherHealthProfessional`},
        {  value: `ImagingDiagnosticClinic`,label: `ImagingDiagnosticClinic`},
        {  value: `Laboratory`,label: `Laboratory`}
    ]);

    const [selectedOptions, setSelectedOptions] = useState();

    const handleSelectChange = (id, selected) => {
        console.log("Row ID:", id); // Logs the ID of the row
        console.log("Selected Options:", selected); // Logs selected options
      
        // Update state to store selections by row ID
        // setSelectedOptions((prevSelected) => ({
        //   ...prevSelected,
        //   [id]: selected || [], // Store selections for the specific ID
        // }));
      };

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const token = getToken();

   

    const handleApiError = useApiErrorHandler();

    const [FaqCategoryListMutation] = useCbhpmListMutation();
    const [DeleteCbhpmMutation] = useDeleteCbhpmMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();


    const getList = async ()=>{
        setLoading(true)
        try{
        const response = await FaqCategoryListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
      if(response.error){

        handleApiError(response.error)
      }
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    setLoading(false)
    }

    useEffect(()=>{
        getList();
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);


    const deleteData = async (id) => {
        confirmAlert({
            title: 'Confirme para excluir',
            message: 'Tem certeza de que deseja excluir este item?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try {
                            const deleteResponse = await DeleteCbhpmMutation({ token, id });
                            if (deleteResponse.data.status) {
                                console.log(deleteResponse.data);
                                notifySuccuess(deleteResponse.data.message);
                                getList(); // Refresh the list after deletion
                            } else {
                                notifyError(deleteResponse.data.message);
                            }
                        } catch (error) {
                            notifyError('An error occurred while deleting the item.');
                            console.error(error);
                        }
                    },
                },
                {
                    label: 'Não',
                    onClick: () => console.log('Cancelled'),
                },
            ],
        });
    };

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const fileInputRef  = useRef(null);

    const formValidate = ()=>{
        const errors  = {}
    //    alert(inputData.file.type)
       if(!inputData.file ){
        errors.file = 'File field is required.'
       }else{
        if(inputData.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && inputData.file.type !== 'application/vnd.ms-excel'){
            errors.file = "please choose any xlsx or xls file";
           }
       }

      

       setErrorData(errors)

       return Object.keys(errors).length === 0;

    }

    const [UploadCbhpmFileMutation] = useUploadCbhpmFileMutation();

    const notifySuccess  = (message)=>toast.success(message)

    const [loading,setLoading] = useState(false)

const importHandler = async(e)=>{
    e.preventDefault();
    if(formValidate()){
        setLoading(true)
        const form_data = new FormData(e.currentTarget)
        form_data.append('file',inputData.file)

        const resp = await UploadCbhpmFileMutation({token,form_data});
        setLoading(false)
        fileInputRef.current.value = "";
        if(resp.data.status){
            notifySuccess(resp.data.message)
        }else{
            notifyError(resp.data.message);
        }
    }
}

  const [checkRow,setCheckRow] = useState([]);
  const [isSelectAllChecked,setIsSelectAllChecked] = useState(false)

  const [selected, setSelected] = useState([]);

const handleCheckbox = async(e,providerId)=>{
    if(e.target.checked){
        setCheckRow([...checkRow,providerId])
    }else{
        setCheckRow(checkRow.filter(i=> i!== providerId))
    }
}

  const handleAllCheckBox = async(e)=>{
        if(e.target.checked){
           setCheckRow(list.map(value=>value._id))
           setIsSelectAllChecked(true)
        }else{
           setCheckRow([])
           setIsSelectAllChecked(false)
        }
    }

    useEffect(()=>{
        console.log(checkRow)
    },[handleAllCheckBox])


    const [activeStatus,setActiveStatus] = useState('')
    const [errorStatus,setErrorStatus] = useState('')

    const [UpdateMultipleStatusMutation] = useUpdateMultipleStatusMutation();

    const updateStatus = async()=>{

     

        if(activeStatus === ''){ 
            setErrorStatus('Please select status.') 

            return false;

        }


        const data = {status:activeStatus,ids:checkRow}
         
      const UpdateMultipleStatusMutationResp = await UpdateMultipleStatusMutation({token,data})

      if(UpdateMultipleStatusMutationResp?.data?.status){
        setCheckRow([])

        notifySuccess('Status Updated Successfully.')
        getList();
      }


    }

    const [UpdateCbhpmAssignRoleMutation] = useUpdateCbhpmAssignRoleMutation();

    const surgeoryRoleHandler = async(cbhpmId,assingProviderRole,index)=>{
        const updatedValues = [...selected]

        updatedValues[index] = assingProviderRole;

        setSelected(updatedValues);

        const assingProviderCbhpm = assingProviderRole.map(option => option.value)

        const data = { cbhpmId, assingProviderCbhpm };

        console.log(data);
      
        const UpdateCbhpmAssignRoleMutationResp = await UpdateCbhpmAssignRoleMutation({ token, data });
      
        if (UpdateCbhpmAssignRoleMutationResp.data.status) {
          notifySuccess(UpdateCbhpmAssignRoleMutationResp.data.message);
          getList(); // Refresh the list
        } else {
          notifyError(UpdateCbhpmAssignRoleMutationResp.data.message || "Failed to update");
        }

    }

  return (
    <Layout>
    <ToastContainer />
    {loading && <Loader />}
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Lista CBHPM</h3> 
                </div>
             
                <div className="col-md-6 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/cbhpm/create`}>Adicionar CBHPM</Link>
                </div>

                {checkRow.length > 0 && (
                    <>
                    <div className='col-md-6'>
                    {/* <div className='col-md-6'> */}

                  <div className='form-group' style={{ display:'flex' }}>
                    {/* <label>Update Status</label> */}
                    <select  className={`form-control ${errorStatus ? 'is-invalid':''}`}
                       value={activeStatus} 
                        onChange={(e)=>{
                            setActiveStatus(e.target.value)
                            setErrorStatus('')
                        }} 
                    >
                        <option value={``}>--Selecione o status--</option>
                        <option value={`active`}>Ativa</option>
                        <option value={`inactive`}>Inativa</option>
                    </select>
                   
                  <Button onClick={updateStatus} className='btn btn-primary'>Atualizar</Button>

                  </div>
                  {errorStatus && (
                        <div className='invalid-feedback'>Selecione o status</div>
                    )}
                  {/* </div> */}
                  {/* <div className='col-md-6'> */}

                  {/* </div> */}
                </div>
                    </>
                 )}   

                <div className={checkRow.length === 0 ?'offset-md-6 col-md-6 text-end mt-3':'col-md-6 text-end mt-3'} >
                <form onSubmit={importHandler} className="d-flex align-items-center mb-3">
            <div className='col-md-6'>
                <input 
                    type='file' 
                    ref={fileInputRef}
                    className={`form-control ${errorData.file ? 'is-invalid':''}`} 
                    placeholder='Select xls file' 
                    onChange={(e)=>{
                      setInputData({...inputData,file:e.target.files[0]})
                      setErrorData({...errorData,file:''});
                    }}
                />
                {errorData.file && (
                    <div className='invalid-feedback'>{errorData.file}</div>
                )}
            </div>
            <div className='col-md-6 '>
                <button type='submit' className="btn btn-primary">Importar arquivo XLS</button>
            </div>
        </form>
                </div>


            </div>
        </div>
           <div className="card-body " style={{ overflowX: 'scroll' }}>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Pesquise aqui..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Por favor selecione--</option>
                       <option value='active'>Ativo</option>
                       <option value='inactive'>Inativo</option>
                    </select>
                </div>
            </div>
           <div className='table-responsive'>
            <table className="table table-bordered ">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th><input  type="checkbox" checked={isSelectAllChecked} onChange={handleAllCheckBox} /></th>
                        <th scope="col" style={{ width:`100` }}>Assign Surgoery</th>
                        <th scope="col" style={{ width:`100` }}>Assign Surgoeries</th>
                        <th scope="col" >ID do grupo</th>
                        <th scope="col">Descrição do grupo</th>
                        <th scope="col">ID do subgrupo</th>
                        <th scope="col">Descrição do subgrupo</th>
                        <th scope="col">ID do procedimento</th>
                        <th scope="col">Descrição do procedimento</th>
                        <th scope="col">Percentagem</th>
                        <th scope="col">Porta</th>
                        <th scope="col">Custo operacional</th>
                        <th scope="col">Auxiliar não</th>
                        <th scope="col">Portão Anestes</th>
                        <th scope="col">Filmes ou documentos</th>
                        <th scope="col">incidência</th>
                        <th scope="col">Você</th>
                        <th scope="col">Status</th>
                        <th scope="col">Ação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                           {/* <td>{index + 1}</td> */}
                           <td>{(currentPage - 1) * 50 + index + 1}</td>
                           <td><input type='checkbox' checked={checkRow.includes(value._id)} onChange={(e)=>{ handleCheckbox(e,value._id)}} /></td>
                      
                            <td  >
                            <div style={{ width: "190px" }}>
            <MultiSelect
                key={index}
                options={options}
                value={selected[index] ||   value.assingProviderCbhpm?.map(item => options.find(option => option.value === item)) || 
                    [] }
                onChange={(selectedOptions) => {
                    surgeoryRoleHandler(value._id, selectedOptions, index);
                }}
                labelledBy="Select Categories"
                />
      </div>
                            </td>
                            <td>{value.groupId}</td>
                            <td>{value.groupDescription}</td>
                            <td>{value.subgroupId}</td>
                            <td>{value.subgroupDescription}</td>
                            <td>{value.procedureID}</td>
                            <td>{value.descriptionOfTheProcedure}</td>
                            <td>{value.Percentage}</td>
                            <td>{value.Porte}</td>
                            <td>{value.operatingCost}</td>
                            <td>{value.auxNo}</td>
                            <td>{value.anestesGate}</td>
                            <td>{value.moviesOrDoc}</td>
                            <td>{value.incidence}</td>
                            <td>{value.uR}</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('Cbhpm', value._id, 'inactive') }} className='btn btn-success text-white'>Ativa</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('Cbhpm', value._id, 'active') }} className='btn btn-danger text-white'>Inativa</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/cbhpm/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                             {value._id !== '670ce09dc0ae0ae1dd9aa6ed' && (
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                             )}
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            </div>
            {totalPages > 1 && (
    <div className="d-flex justify-content-center mt-4">
        <nav>
            <ul className="pagination">
              
                {currentPage > 3 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    </>
                )}

                {/* Show a range of pages around the current page */}
                {Array.from({ length: totalPages }, (_, index) => index + 1)
                    .filter(page => page >= currentPage - 2 && page <= currentPage + 2)
                    .map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>
                                {page}
                            </button>
                        </li>
                    ))}

                {/* Show "..." and the last page if there are more than 2 pages left */}
                {currentPage < totalPages - 2 && (
                    <>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    </div>
)}

        </div>
    </div>
</Layout>
  )
}
