// staging server

// export const BASEURL = 'http://v4.checkprojectstatus.com:9999'

// Local server

// export const BASEURL = 'http://localhost:9999'


// Production server 

export const BASEURL = 'https://api.qmedicina.com.br'