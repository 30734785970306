// import { configureStore } from '@reduxjs/toolkit'
// import { setupListeners } from '@reduxjs/toolkit/query'
// import { projectApi } from '../services/ProjectApi'
// import userReducer from '../fetures/userSlice'

// export const store = configureStore({
//   reducer: {
//     [projectApi.reducerPath]: projectApi.reducer,
//     user_info:userReducer,
//   },

//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(projectApi.middleware),
// })

// setupListeners(store.dispatch)

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // by default uses localStorage for web
import { projectApi } from '../services/ProjectApi';
import userReducer from '../fetures/userSlice';

// Persist configuration for user_info
const persistConfig = {
  key: 'user_info',
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    [projectApi.reducerPath]: projectApi.reducer,
    user_info: persistedUserReducer, // Use persisted reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Necessary because redux-persist works with non-serializable data
    }).concat(projectApi.middleware),
});

setupListeners(store.dispatch);

// Create a persistor object
export const persistor = persistStore(store);
