import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatPath } from '../utils/formatPath'

export default function Layout({children}) {


  const location = useLocation();

  const pathname =  location.pathname;

  const parts = pathname.split('/')

  let adminPath = '';

  if (parts.length > 1) {
    adminPath = parts[2];
  }

  let formattedPath = formatPath(adminPath);

  // Handle special cases
  formattedPath = formattedPath === 'Dashboard' ? 'Painel' : formattedPath;

  formattedPath = formattedPath === 'Category' ? 'Categoria' : formattedPath;

  formattedPath = formattedPath === 'Hospital Requests' ? 'Solicitações hospitalares' : formattedPath;

  formattedPath = formattedPath === 'Anesthesia Group' ? 'Grupo de Anestesia' : formattedPath;
  formattedPath = formattedPath === 'Hospital' ? 'Hospital' : formattedPath;
  formattedPath = formattedPath === 'Imaging Diagnostic Clinic' ? 'Clínica de diagnóstico por imagem' : formattedPath;
  formattedPath = formattedPath === 'Laboratory' ? 'Laboratório' : formattedPath;
  formattedPath = formattedPath === 'Pathology Clinic' ? 'Clínica de Patologia' : formattedPath;
  formattedPath = formattedPath === 'Special Materials Company' ? 'Empresa de Materiais Especiais' : formattedPath;
  formattedPath = formattedPath === 'Surgeon' ? 'Cirurgiã' : formattedPath;
  formattedPath = formattedPath === 'Freelance Anesthesiologist' ? 'Anestesiologista autônomo' : formattedPath;
  formattedPath = formattedPath === 'General Practitioner' ? 'Clínico Geral' : formattedPath;
  formattedPath = formattedPath === 'Specialist Clinician' ? 'Clínico Especialista' : formattedPath;
  formattedPath = formattedPath === 'Other Health Professional' ? 'Outro profissional de saúde' : formattedPath;


  formattedPath = formattedPath === 'Patients' ? 'Pacientes' : formattedPath;
  formattedPath = formattedPath === 'Patient' ? 'Pacientes' : formattedPath;


  formattedPath = formattedPath === 'Price Management Anesthesiologist Group' ? 'Grupo de Anestesistas de Gestão de Preços' : formattedPath;
  formattedPath = formattedPath === 'Price Management Freelance Anesthesiologist' ? 'Anestesista Freelancer em Gestão de Preços' : formattedPath;
  formattedPath = formattedPath === 'Price Management Pathology' ? 'Patologia de Gestão de Preços' : formattedPath;
  formattedPath = formattedPath === 'Price Management Surgeon' ? 'Cirurgião de gerenciamento de preços' : formattedPath;
  formattedPath = formattedPath === 'Price Management Laboratory' ? 'Laboratório de Gestão de Preços' : formattedPath;
  formattedPath = formattedPath === 'Price Management Image Diagnostic' ? 'Diagnóstico de imagem de gerenciamento de preços' : formattedPath;
  
  formattedPath = formattedPath === 'Blogs' ? 'Blogues' : formattedPath;

  formattedPath = formattedPath === 'Blog' ? 'Blogues' : formattedPath;
  formattedPath = formattedPath === 'Case Studies' ? 'Estudos de caso' : formattedPath;
  formattedPath = formattedPath === 'Videos' ? 'Vídeos' : formattedPath;
  formattedPath = formattedPath === 'Video' ? 'Vídeos' : formattedPath;
  formattedPath = formattedPath === 'Change Password' ? 'Alterar a senha' : formattedPath;
  formattedPath = formattedPath === 'Profile' ? 'Perfil' : formattedPath;

  

  


  formattedPath = formattedPath === 'Transactions' ? 'Transações' : formattedPath;
  formattedPath = formattedPath === 'Demo Requests' ? 'Solicitações de demonstração' : formattedPath;
  formattedPath = formattedPath === 'Contact Us' ? 'Contate-nos' : formattedPath;
  formattedPath = formattedPath === 'Faq Category' ? 'Categoria de perguntas frequentes' : formattedPath;
  formattedPath = formattedPath === 'Faq' ? 'Perguntas frequentes' : formattedPath;
  formattedPath = formattedPath === 'Testimonials' ? 'Depoimentos' : formattedPath;
  formattedPath = formattedPath === 'Page' ? 'Página' : formattedPath;
  formattedPath = formattedPath === 'Settings' ? 'Configurações' : formattedPath;


  
  

  formattedPath = formattedPath === 'Faq' ? 'FAQ' : formattedPath;
  formattedPath = formattedPath === 'Faq Category' ? 'FAQ Category' : formattedPath;
  formattedPath = formattedPath === 'Cbhpm' ? 'CBHPM' : formattedPath;

  

  return (
   <>
    <Header />

    <SideBar />
    <main id="main" class="main">
    
    <div class="pagetitle row mt-3">
    <div className='col-md-6'>
      <h1>{formattedPath}</h1>
      </div>
      <div className='col-md-6 text-end'>
      {/* <nav> */}
        <ol className="breadcrumb text-end" style={{ float:'right' }} >
          <li className="breadcrumb-item"><Link to={`/`}>Lar</Link></li>
          <li className="breadcrumb-item active">{formattedPath}</li>
        </ol>
      {/* </nav> */}
    </div>
    </div>

    {children}
    </main>
    

    <Footer />
   </>
  )
}
