// src/hooks/useCkeditorUploadAdapter.js

import { useUploadImageMutation } from '../services/ProjectApi';
import { getToken } from '../services/Token';

export function useCkeditorUploadAdapter() {
    const [uploadImage] = useUploadImageMutation();
    const token = getToken();

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then((file) => {
                        data.append('upload', file);
                        uploadImage({ token, formData: data })
                            .unwrap()
                            .then((response) => {
                                resolve({ default: response.url });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                });
            },
        };
    }

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return { MyCustomUploadAdapterPlugin };
}
