import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { useChangeStatusMutation, useDeleteUserMutation, useUserListMutation } from '../../../services/ProjectApi';
import { getToken, removeToken } from '../../../services/Token';
import { toast, ToastContainer } from 'react-toastify';
import { BASEURL } from '../../../Config';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';

import NotImgFound from '../../../assets/img/user.png'


export default function User() {

    const navigate = useNavigate();

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [userList,setUserList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const [userListMutation] = useUserListMutation();
    const [deleteUserMutation] = useDeleteUserMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();


    const handleApiError = useApiErrorHandler();

    const getUserList = async ()=>{
        try{
        const userListMutationResp = await userListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
        // console.log(userListMutationResp.error.data.statusCode)
      if(userListMutationResp.error){

        handleApiError(userListMutationResp.error)
      }
       
        if(userListMutationResp.data.status){
            setUserList(userListMutationResp.data.data);
            setTotalPages(userListMutationResp.data.pagination.last_page);
        }
    } catch (error) {
        // console.log('vikas')
        // if (error?.statusCode === 403) {
        // console.log(error)

            // toast.error('You are not authorized to perform this action.');
            // removeToken();
            // navigate('/')
        // }
    }
    }

    useEffect(()=>{
        getUserList();
    },[]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getUserList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput, currentPage]);

    const deleteUser = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            const deleteUserMutationResp = await deleteUserMutation({token,id});
            if(deleteUserMutationResp.data.status){
                getUserList();
                notifySuccuess(deleteUserMutationResp.data.message);
            } else {
                notifyError(deleteUserMutationResp.data.message);
            }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getUserList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Layout>
            <ToastContainer />
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 mt-2 pagetitle">
                            <h3>User List</h3> 
                        </div>
                        <div className="col-md-6 text-end">
                            <Link className="btn btn-primary" to={`/admin/create-user`}>Add User</Link>
                        </div>
                    </div>
                </div>
                   <div className="card-body " style={{ overflowX: 'scroll' }}>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <input 
                                type='text' 
                                className='form-control' 
                                name='search' 
                                id='search' 
                                onChange={(e)=>{
                                    setSearchInput({...searchInput, keyword: e.target.value})
                                }} 
                                value={searchInput.keyword} 
                                placeholder='Pesquise aqui..' 
                            />
                        </div>
                        <div className="col-md-3">
                            <select 
                                name='status' 
                                value={searchInput.status} 
                                onChange={(e)=>{
                                    setSearchInput({...searchInput, status: e.target.value})
                                }} 
                                className='form-control'
                            >
                                <option value=''>--Por favor selecione--</option>
                               <option value='active'>Ativo</option>
                               <option value='inactive'>Inativo</option>
                            </select>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Imagem</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            userList.length > 0 ? userList.map((value, index)=>(
                                <tr key={index}>
                                    {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                                    <td>{index + 1}</td>
                                    <td><img src={`${BASEURL}${value.image || NotImgFound } `} width="50px" height="50px" alt="User" /></td>
                                    <td>{value.name}</td>
                                    <td>{value.email}</td>
                                    <td>{value.status === 'active' ? (
                                        <Link onClick={()=>{ changeStatus('users', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                                    ):(
                                        <Link onClick={()=>{ changeStatus('users', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                                    )}</td>
                                    <td>
                                        <Link to={`/admin/user/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                        <Link onClick={()=>{ deleteUser(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                                    </td>
                                </tr>
                            )):(
                                <tr>
                                    <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center text-end">
                        <nav>
                            <ul className="pagination text-end">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
