import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { useAddUserMutation, useAdminLoginMutation, useFaqCategoryListMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';


import { MultiSelect } from "react-multi-select-component";


// import $ from 'jquery';
// import 'select2/dist/js/select2.min.js';
// import 'select2/dist/css/select2.min.css';

export default function AddUser() {



  const fileInputRef = useRef(null);

  const notifyError = (message)=>toast.error(message);
  const notifySuccuess = (message)=>toast.success(message);

  const navigate = useNavigate();

  const [AddUserMutation] = useAddUserMutation();

  const token = getToken();

  const [inputData,setInputData] = useState({name:'',email:'',password:'',phone:'',dob:'',description:'',gender:'',hobby:[],country:'',faqCategoryId:[]});
  const [errorData,setErrorData] = useState({});

  const [imagePreview ,setImagePreview] = useState(''); 
  const [selected , setSelected] = useState([]);


  const validateForm = ()=>{
   const errors = {};

   if (!inputData.name) errors.name = 'Name is required';
   if (!inputData.email) errors.email = 'Email is required';
   if (!inputData.password) errors.password = 'Password is required';
   if (!inputData.phone) errors.phone = 'Phone is required';
   if (!inputData.dob) errors.dob = 'Dath of Birth is required';
   if (!inputData.description) errors.description = 'Descripiton  is required';
   if (!inputData.gender) errors.gender = 'Gender  is required';
   if (!inputData.hobby) errors.hobby = 'Hobby  is required';
   if (!inputData.country) errors.country = 'Country  is required';
   if (!inputData.image) errors.image = 'Image  is required';
   if (inputData.hobby.length === 0) errors.hobby = 'At least one hobby is required';
   if (!inputData.faqCategoryId.length) errors.faqCategoryId = 'At least one FAQ Category is required';

   setErrorData(errors);

   return Object.keys(errors).length === 0;
   
  }

  const submitHandler = async(e)=>{
    // alert('vikas')
    e.preventDefault();

    if (validateForm()) {

    const form_data = new FormData(e.currentTarget);

    form_data.append('hobby',inputData.hobby)
  //  console.log(form_data.get('hobby'))

  inputData.faqCategoryId.forEach((id) => form_data.append('faqCategoryId[]', id));


    const AddUserMutationResp = await AddUserMutation({token,form_data});
    console.log(AddUserMutationResp)
    
   if(AddUserMutationResp.data.status){
    setInputData({name:'',email:'',password:'',phone:'',gender:'',description:'',hobby:[],image:'',dob:'',country:''})
    if(fileInputRef.current){
      fileInputRef.current.value = ''
    }

    setImagePreview('')
    notifySuccuess(AddUserMutationResp.data.message);
    // navigate('/admin/users')
   }else{
    if(AddUserMutationResp.data.errors){
      setErrorData(AddUserMutationResp.data.errors);
    }else{
      notifyError(AddUserMutationResp.data.message);
    }
   }
  }
  }
  

  const handleHobbyChange = (e)=>{
    const newHobby =  e.target.value;
    const updatedHobby = inputData.hobby.includes(newHobby) ? inputData.hobby.filter(c=>c!== newHobby) : [...inputData.hobby,newHobby]
   setInputData({...inputData,hobby:updatedHobby})
   setErrorData({...errorData,hobby:''})
  }

  const [faqCategoryList,setFaqCategoryList] = useState([])


  const [FaqCategoryListMutation] = useFaqCategoryListMutation();
  const getFaqCategory = async ()=>{
    const FaqCategoryListMutationResp = await FaqCategoryListMutation({token,status:'active'});
    console.log(FaqCategoryListMutationResp)
    if(FaqCategoryListMutationResp.data.status){
      setFaqCategoryList(FaqCategoryListMutationResp.data.data);
    }
  }

  useEffect(()=>{
    getFaqCategory()
  },[])

console.log(inputData)
  return (
    <Layout>
    <ToastContainer />
        <div class="card">
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add User Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/users`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
            <div class="card-body">
            <div className='row'>
              <div className='col-md-6'>
              <h5 class="card-title">
              {/* General Form Elements */}
              </h5>
              </div>
              <div className='col-md-6 text-end'>
                {/* <Link to={`/admin/users`} className='btn btn-danger '>Back</Link> */}
              </div>
            </div>

              <form onSubmit={submitHandler}>
                <div class="row mb-3">
                  <label for="name" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" id='name' value={inputData.name}  onChange={(e)=>{
                       setInputData({...inputData,name:e.target.value})
                       setErrorData({...errorData,name:''})
                       }} name='name'   class={`form-control ${errorData.name ? 'is-invalid':''}`}/>
                 {errorData.name && (
                  <div className='invalid-feedback'>{errorData.name}</div>
                 )}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="email" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="text"  value={inputData.email} name='email' onChange={(e)=>{ 
                      setInputData({...inputData,email:e.target.value})
                      setErrorData({...errorData,email:''})
                      }}   class={`form-control ${errorData.email ? 'is-invalid':''}`}/>
                  {errorData.email && (
                    <div className='invalid-feedback'>{errorData.email}</div>
                  )}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="password" class="col-sm-2 col-form-label">Password</label>
                  <div class="col-sm-10">
                    <input type="password" name='password' id='password' value={inputData.password} onChange={(e)=>{ 
                      setInputData({...inputData,password:e.target.value})
                      setErrorData({...errorData,password:''})
                      }}  class={`form-control ${errorData.password ? 'is-invalid':''}`}/>
                 {errorData.password && (
                  <div className='invalid-feedback'>{errorData.password}</div>
                 )}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="phone" class="col-sm-2 col-form-label">Phone</label>
                  <div class="col-sm-10">
                    <input type="number" id='phone' name='phone' onChange={(e)=>{ 
                      setInputData({...inputData,phone:e.target.value})
                      setErrorData({...errorData,phone:''})
                      }}  value={inputData.phone} class={`form-control ${errorData.phone ? 'is-invalid':''}`}/>
                  {errorData.phone && (
                    <div className='invalid-feedback'>{errorData.phone}</div>
                  )}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="image" class="col-sm-2 col-form-label">Image</label>
                  <div class="col-sm-10">
                    <input name='image'  class={`form-control ${errorData.image ? 'is-invalid':''}`} type="file" onChange={(e)=>{
                       setInputData({...inputData,image:e.target.files[0]})
                       setImagePreview(URL.createObjectURL(e.target.files[0]))
                       setErrorData({...errorData,image:''})
                       }}  id="image" ref={fileInputRef} />
                 {errorData.image && (
                  <div className='invalid-feedback'>{errorData.image}</div>
                 )}
                 {imagePreview && (
                  <div className='col-md-12 mt-4'>
                    <img src={imagePreview} width='100' height='100' />
                  </div>
                 )}
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="dob" class="col-sm-2 col-form-label">Date</label>
                  <div class="col-sm-10">
                    <input type="date" name='dob' id='dob' onChange={(e)=>{ 
                      setInputData({...inputData,dob:e.target.value})
                      setErrorData({...errorData,dob:''})
                      }}  value={inputData.dob} class={`form-control ${errorData.dob ?'is-invalid':''}`}/>
                 {errorData.dob && (
                  <div className='invalid-feedback'>{errorData.dob}</div>
                 )}
                  </div>
                </div>
             

              
                <div class="row mb-3">
                  <label for="description" class="col-sm-2 col-form-label">About Description</label>
                  <div class="col-sm-10">
                    <textarea name='description' id='description' onChange={(e)=>{ 
                      
                    setInputData({...inputData,description:e.target.value})
                    setErrorData({...errorData,description:''})
                    }}  value={inputData.description} class={`form-control ${errorData.description ? 'is-invalid':''}`} style={{ height:`100px` }}></textarea>
                  {errorData.description && (
                    <div className='invalid-feedback'>{errorData.description}</div>
                  )}
                  </div>
                </div>
                <fieldset class="row mb-3">
                  <legend class="col-form-label col-sm-2 pt-0">Gender</legend>
                  <div class="col-sm-10">
                    <div class="form-check">
                      <input class={`form-check-input ${errorData.gender ? 'is-invalid':''}`} type="radio" name="gender" id="male" value="male" 
                      checked={inputData.gender === 'male'}
                      onChange={(e)=>{
                         setInputData({...inputData,gender:'male'})
                         setErrorData({...errorData,gender:''})
                         }}

                      />
                      <label class="form-check-label" for="male">
                        Male
                      </label>
                    </div>
                    <div class="form-check">
                      <input class={`form-check-input ${errorData.gender ?'is-invalid':''}`} type="radio" name="gender" id="female" value="female"
                        checked={inputData.gender === 'female'}
                        onChange={(e)=>{ 
                          setInputData({...inputData,gender:'female'})
                          setErrorData({...errorData,gender:''})
                          }}
                      />
                      <label class="form-check-label" for="female">
                       Female
                      </label>
                    </div>
                    {errorData.gender && (
                      <div className='error'>{errorData.gender}</div>
                    )}
                    
                  </div>
                </fieldset>
                {/* <div class="row mb-3">
                  <legend class="col-form-label col-sm-2 pt-0">Hobby</legend>
                  <div class="col-sm-10">

                    <div class="form-check">
                      <input class="form-check-input" value={`cricket`} name='hobby[]' checked={ Array.isArray(inputData.hobby) && inputData.hobby.includes('cricket')} 
                      onChange={(e)=>{
                          const newHobby = e.target.value; 

                          const updatedHobby = inputData.hobby.includes(newHobby)? inputData.hobby.filter(c=>c !== newHobby) : [...inputData.hobby,newHobby]
                          setInputData({...inputData,category:updatedHobby})
                      }}
                      type="checkbox" id="cricket" />
                      <label class="form-check-label" for="cricket">
                       Cricket
                      </label>
                    </div>

                    <div class="form-check">
                      <input name='hobby[]' value={`football`} class="form-check-input" type="checkbox"
                      checked={Array.isArray(inputData.hobby) && inputData.hobby.includes('football')}
                       id="football" 

                       onChange={(e)=>{
                          const newHobby = e.target.value; 

                          const updatedHobby = inputData.hobby.includes(newHobby)? inputData.hobby.filter(c=>c !== newHobby) : [...inputData.hobby,newHobby]
                    setInputData({...inputData,category:updatedHobby})
                      }}
                       />
                      <label class="form-check-label" for="football">
                        Football
                      </label>
                    </div>

                  </div>
                </div> */}

                <div class="row mb-3">
  <legend class="col-form-label col-sm-2 pt-0">Hobby</legend>
  <div class="col-sm-10">

    <div class="form-check">
      <input class={`form-check-input ${errorData.hobby?'is-invalid':''}`} value={`cricket`} name='hobby[]' 
      checked={Array.isArray(inputData.hobby) && inputData.hobby.includes('cricket')} 
      onChange={handleHobbyChange}
      type="checkbox" id="cricket" />
      <label class="form-check-label" for="cricket">
       Cricket
      </label>
    </div>

    <div class="form-check">
      <input name='hobby[]' value={`football`} class={`form-check-input ${errorData.hobby?'is-invalid':''}`} type="checkbox"
      checked={Array.isArray(inputData.hobby) && inputData.hobby.includes('football')}
      id="football" 
      onChange={handleHobbyChange}
      />
      <label class="form-check-label" for="football">
        Football
      </label>
    </div>
    <div class="form-check">
      <input name='hobby[]' value={'cheese'} className={`form-check-input ${errorData.hobby?'is-invalid':''} `} type='checkbox' id='cheese'
    checked={Array.isArray(inputData.hobby) && inputData.hobby.includes('cheese') }
    onChange={handleHobbyChange}

      />
   <label className='form-check-label' for='cheese'>Cheese</label>
   
    </div>
      {errorData.hobby && (
        <div className='error'>{errorData.hobby}</div>
      )}
  </div>
</div>


              

                <div class="row mb-3">
                  <label htmlFor='country' class="col-sm-2 col-form-label">Country</label>
                  <div class="col-sm-10">
                    <select onChange={(e)=>{ 
                      setInputData({...inputData,country:e.target.value})
                      setErrorData({...errorData,country:''})
                      }} id='country' name='country' value={inputData.country} class={`form-select ${errorData.country?'is-invalid':''} `} aria-label="Default select example">
                      <option value={``} selected>--Por favor selecione--</option>
                      <option value={`India`}>India</option>
                      <option value={`Us`}>Us</option>
                      <option value={`Dubai`}>Dubai</option>
                    </select>
                    {errorData.country && (
                    <div className='invalid-feedback'>{errorData.country}</div>
                  )}
                </div>
                  </div>


              <div class="row mb-3">
                  <label for="faqCategoryId" class="col-sm-2 col-form-label">FAQ Category</label>
                  <div class="col-sm-10">
                    <MultiSelect
                      options={faqCategoryList.map(category => ({
                        label: category.title,
                        value: category._id
                      }))}
                      value={selected}
                      onChange={(selectedOptions) => {
                        setSelected(selectedOptions);
                        setInputData({ ...inputData, faqCategoryId: selectedOptions.map(option => option.value) });
                        setErrorData({ ...errorData, faqCategoryId: '' });
                      }}
                      labelledBy="Select Categories"
                    />
                    {errorData.faqCategoryId && (
                      <div className='error'>{errorData.faqCategoryId}</div>
                    )}
                  </div>
                </div>



                <div class="row mb-3">
                  <label class="col-sm-2 col-form-label"></label>
                  <div class="col-sm-10">
                    <button type="submit" class="btn btn-primary">Submit Form</button>
                  </div>
                </div>

              </form>

            </div>
          </div>
    </Layout>
  )
}
