import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { Link, useParams } from 'react-router-dom';
import { useEditUserMutation, useFaqCategoryListMutation, useUpdateUserMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { BASEURL } from '../../../Config';
import { toast, ToastContainer } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

export default function EditUser() {
    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const { id } = useParams();
    const [EditUserMutation] = useEditUserMutation();
    const token = getToken();

    const [updateUserMutation] = useUpdateUserMutation();

    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        dob: '',
        description: '',
        gender: '',
        hobby: [],
        country: '',
        faqCategoryId: [],
        image: '',
    });
    const [errorData, setErrorData] = useState({});
    const [imagePreview, setImagePreview] = useState('');
    const [selected, setSelected] = useState([]);

    const viewDetail = async (id) => {
        try {
            const EditUserMutationResp = await EditUserMutation({ token, id });
            const data = EditUserMutationResp.data;

            setInputData({
                ...data.data,
                faqCategoryId: data.data.faqCategoryId.map((category) => category._id),
            });
            setImagePreview(BASEURL + data.data.image);

            const categoryOptions = data.data.faqCategoryId.map((category) => ({
                value: category._id,
                label: category.title,
            }));
            setSelected(categoryOptions);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const [FaqCategoryListMutation] = useFaqCategoryListMutation();
    const [faqCategoryList, setFaqCategoryList] = useState([]);

    const getFaqCategoryList = async () => {
        const FaqCategoryListMutationResp = await FaqCategoryListMutation({ token, status: 'active' });
        if (FaqCategoryListMutationResp.data.status) {
            setFaqCategoryList(
                FaqCategoryListMutationResp.data.data.map((category) => ({
                    value: category._id,
                    label: category.title,
                }))
            );
        }
    };

    useEffect(() => {
        getFaqCategoryList();
        viewDetail(id);
    }, [id]);

    const changeHobby = (e) => {
        const newHobby = e.target.value;
        const updatedHobby = inputData.hobby.includes(newHobby)
            ? inputData.hobby.filter((c) => c !== newHobby)
            : [...inputData.hobby, newHobby];
        setInputData({ ...inputData, hobby: updatedHobby });
        setErrorData({ ...errorData, hobby: '' });
    };

    const validateForm = () => {
        const errors = {};
        if (!inputData.name) errors.name = 'Name is required';
        if (!inputData.email) errors.email = 'Email is required';
        if (!inputData.password) errors.password = 'Password is required';
        if (!inputData.phone) errors.phone = 'Phone is required';
        if (!inputData.dob) errors.dob = 'Date of Birth is required';
        if (!inputData.description) errors.description = 'Description is required';
        if (!inputData.gender) errors.gender = 'Gender is required';
        if (inputData.hobby.length === 0) errors.hobby = 'At least one hobby is required';
        if (!inputData.country) errors.country = 'Country is required';
        if (!inputData.image) errors.image = 'Image is required';
        if (inputData.faqCategoryId.length === 0) errors.faqCategoryId = 'At least one FAQ Category is required';

        setErrorData(errors);

        return Object.keys(errors).length === 0;
    };

    const updateUser = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const form_data = new FormData();
            form_data.append('name', inputData.name);
            form_data.append('email', inputData.email);
            form_data.append('password', inputData.password);
            form_data.append('phone', inputData.phone);
            form_data.append('dob', inputData.dob);
            form_data.append('description', inputData.description);
            form_data.append('gender', inputData.gender);
            form_data.append('country', inputData.country);
            form_data.append('id', id);

            inputData.hobby.forEach((hobby) => form_data.append('hobby[]', hobby));
            selected.forEach((category) => form_data.append('faqCategoryId[]', category.value));

            if (inputData.image instanceof File) {
                form_data.append('image', inputData.image);
            }

            const updateUserMutationResp = await updateUserMutation({ id, token, form_data });
            if (updateUserMutationResp.data.status) {
                notifySuccess(updateUserMutationResp.data.message);
                viewDetail(id);
            } else {
                if (updateUserMutationResp.data.errors) {
                    setErrorData(updateUserMutationResp.data.errors);
                } else {
                    notifyError(updateUserMutationResp.data.message);
                }
            }
        }
    };

    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6'>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Edit User Form</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/users`} className='btn btn-danger'>Voltar</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={updateUser}>
                        <ToastContainer />
                        <div className='form-group'>
                            <label htmlFor='name'>Name *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, name: e.target.value });
                                    setErrorData({ ...errorData, name: '' });
                                }}
                                className={`form-control ${errorData.name ? 'is-invalid' : ''}`}
                                name='name'
                                value={inputData.name}
                                id='name'
                            />
                            {errorData.name && (
                                <div className='invalid-feedback'>{errorData.name}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='email'>Email *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, email: e.target.value });
                                    setErrorData({ ...errorData, email: '' });
                                }}
                                className={`form-control ${errorData.email ? 'is-invalid' : ''}`}
                                name='email'
                                value={inputData.email}
                                id='email'
                            />
                            {errorData.email && (
                                <div className='invalid-feedback'>{errorData.email}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='password'>Password *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, password: e.target.value });
                                    setErrorData({ ...errorData, password: '' });
                                }}
                                className='form-control'
                                name='password'
                                id='password'
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='phone'>Phone *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, phone: e.target.value });
                                    setErrorData({ ...errorData, phone: '' });
                                }}
                                className={`form-control ${errorData.phone ? 'is-invalid' : ''}`}
                                name='phone'
                                value={inputData.phone}
                                id='phone'
                            />
                            {errorData.phone && (
                                <div className='invalid-feedback'>{errorData.phone}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='description'>Descrição</label>
                            <textarea
                                className={`form-control ${errorData.description ? 'is-invalid' : ''}`}
                                name='description'
                                onChange={(e) => {
                                    setInputData({ ...inputData, description: e.target.value });
                                    setErrorData({ ...errorData, description: '' });
                                }}
                                id='description'
                                value={inputData.description}
                            ></textarea>
                            {errorData.description && (
                                <div className='invalid-feedback'>{errorData.description}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='dob'>Date of birth *</label>
                            <input
                                type='date'
                                onChange={(e) => {
                                    setInputData({ ...inputData, dob: e.target.value });
                                    setErrorData({ ...errorData, dob: '' });
                                }}
                                className={`form-control ${errorData.dob ? 'is-invalid' : ''}`}
                                name='dob'
                                value={inputData.dob}
                                id='dob'
                            />
                            {errorData.dob && (
                                <div className='invalid-feedback'>{errorData.dob}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='gender'>Gender *</label>
                            <select
                                className={`form-control ${errorData.gender ? 'is-invalid' : ''}`}
                                name='gender'
                                onChange={(e) => {
                                    setInputData({ ...inputData, gender: e.target.value });
                                    setErrorData({ ...errorData, gender: '' });
                                }}
                                value={inputData.gender}
                                id='gender'
                            >
                                <option value=''>Select Gender</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                            </select>
                            {errorData.gender && (
                                <div className='invalid-feedback'>{errorData.gender}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label>Hobby *</label>
                            <div className='d-flex flex-wrap'>
                                {['singing', 'dancing', 'acting', 'writing'].map((hobby) => (
                                    <div className='form-check form-check-inline' key={hobby}>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id={hobby}
                                            value={hobby}
                                            checked={inputData.hobby.includes(hobby)}
                                            onChange={changeHobby}
                                        />
                                        <label className='form-check-label' htmlFor={hobby}>
                                            {hobby.charAt(0).toUpperCase() + hobby.slice(1)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            {errorData.hobby && (
                                <div className='text-danger'>{errorData.hobby}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='country'>Country *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({ ...inputData, country: e.target.value });
                                    setErrorData({ ...errorData, country: '' });
                                }}
                                className={`form-control ${errorData.country ? 'is-invalid' : ''}`}
                                name='country'
                                value={inputData.country}
                                id='country'
                            />
                            {errorData.country && (
                                <div className='invalid-feedback'>{errorData.country}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='faqCategoryId'>FAQ Categories *</label>
                            <MultiSelect
                                options={faqCategoryList}
                                value={selected}
                                onChange={setSelected}
                                labelledBy='Select'
                                className={errorData.faqCategoryId ? 'is-invalid' : ''}
                            />
                            {errorData.faqCategoryId && (
                                <div className='text-danger'>{errorData.faqCategoryId}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='image'>Profile Image *</label>
                            <input
                                type='file'
                                name='image'
                                onChange={(e) => {
                                    setInputData({ ...inputData, image: e.target.files[0] });
                                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                                    setErrorData({ ...errorData, image: '' });
                                }}
                                className={`form-control ${errorData.image ? 'is-invalid' : ''}`}
                            />
                            {imagePreview && (
                                <img
                                    src={imagePreview}
                                    alt='Profile Preview'
                                    className='mt-2'
                                    width='100'
                                    height='100'
                                />
                            )}
                            {errorData.image && (
                                <div className='invalid-feedback'>{errorData.image}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <button type='submit' className='btn btn-primary'>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}
