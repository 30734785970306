import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {  useHealthcareInstituteDetailMutation, useUpdatePermissionhealthcareInstitutionMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';

export default function HealthcareInstitutionsDetail() {

const {id} = useParams()

const [HealthcareInstituteDetailMutation] =  useHealthcareInstituteDetailMutation();

const viewDetail = async(id)=>{
  const editResponse =   await HealthcareInstituteDetailMutation({token,id})
    console.log(editResponse)
  if(editResponse.data.status){
    setInputData(editResponse.data.data);
    setPermissions(editResponse.data.data.permission || {});

  }
}

useEffect(()=>{
    viewDetail(id);
},[id])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        setErrorData(errors)
        return Object.keys(errors).length === 0;
    }



    const [HealthcareInstitutionDetailMutation] = useHealthcareInstituteDetailMutation();

    const [UpdatePermissionhealthcareInstitutionMutation] = useUpdatePermissionhealthcareInstitutionMutation();

    const [permissions, setPermissions] = React.useState({
        operatingRoomHourlyRate: "false",
        recoveryRoomHourlyRate: "false",
        sharedRoomDailyRate: "false",
        privateRoomDailyRate: "false",
          icuDailyRate: "false",
         managePricingPermmision: "false",    
         nonNeoplasticPrice: "false",
         neoplasticPrice: "false",
         operativeFrozenPrice: "false",
         ucoValue: "false",
         imageCost: "false",

      });
    

const token = getToken();


const navigate = useNavigate();

const handleBack = ()=>{
    return navigate(-1)
}


const handleCheckboxChange = async (e, permissionKey) => {
    const { checked } = e.target;

    const updatedPermissions = {
      ...permissions,
      [permissionKey]: checked ? 'true' : 'false',
    };
    setPermissions(updatedPermissions);

    const form_data = {
      _method: 'PUT',
      permission: updatedPermissions,
    };

    const updateResponse = await UpdatePermissionhealthcareInstitutionMutation({ id, token, form_data });
    if (updateResponse.data.status) {
      toast.success('Permissões atualizadas com sucesso');
    } else {
      toast.error('Failed to update permissions');
    }
  };

console.log(inputData?.stateName)


  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Detalhe da instituição de saúde</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to='#' onClick={handleBack} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit=''>
            <ToastContainer />

            <div className='row'>
            <div className='col-md-6'>
            <div className='form-group'>
                    <label htmlFor='companyName'>nome da empresa  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,companyName:e.target.value})
                        setErrorData({...errorData,companyName:''})
                    }} className={`form-control ${errorData.companyName ? 'is-invalid':''}`} name='companyName' value={inputData.companyName} id='companyName' />
                {errorData.companyName && (
                    <div className='invalid-feedback'>{errorData.companyName}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='companyFantasyName'>Nome Fantasia da Empresa  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,companyFantasyName:e.target.value})
                        setErrorData({...errorData,companyFantasyName:''})
                    }} className={`form-control ${errorData.companyFantasyName ? 'is-invalid':''}`} name='companyFantasyName' value={inputData.companyFantasyName} id='companyFantasyName' />
                {errorData.companyFantasyName && (
                    <div className='invalid-feedback'>{errorData.companyFantasyName}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='CNPJ'>CNPJ  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,CNPJ:e.target.value})
                        setErrorData({...errorData,CNPJ:''})
                    }} className={`form-control ${errorData.CNPJ ? 'is-invalid':''}`} name='CNPJ' value={inputData.CNPJ} id='CNPJ' />
                {errorData.CNPJ && (
                    <div className='invalid-feedback'>{errorData.CNPJ}</div>
                )}
                </div>
                </div>

                {inputData.type === 'AnesthesiaGroup' && (
                  <>
                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='RQE'>RQE  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,RQE:e.target.value})
                        setErrorData({...errorData,RQE:''})
                    }} className={`form-control ${errorData.RQE ? 'is-invalid':''}`} name='RQE' value={inputData.RQE} id='RQE' />
                {errorData.RQE && (
                    <div className='invalid-feedback'>{errorData.RQE}</div>
                )}
                </div>
                </div>

                </>

                )}

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='email'>E-mail  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,email:e.target.value})
                        setErrorData({...errorData,email:''})
                    }} className={`form-control ${errorData.email ? 'is-invalid':''}`} name='email' value={inputData.email} id='email' />
                {errorData.email && (
                    <div className='invalid-feedback'>{errorData.email}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='phone'>Telefone  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,phone:e.target.value})
                        setErrorData({...errorData,phone:''})
                    }} className={`form-control ${errorData.phone ? 'is-invalid':''}`} name='phone' value={inputData.phone} id='phone' />
                {errorData.phone && (
                    <div className='invalid-feedback'>{errorData.phone}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='stateName'>Nome do estado  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,stateName:e.target.value})
                        setErrorData({...errorData,stateName:''})
                    }} className={`form-control ${errorData.stateName ? 'is-invalid':''}`} name='stateName' value={inputData.stateName} id='stateName' />
                {errorData.stateName && (
                    <div className='invalid-feedback'>{errorData.stateName}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='address'>Endereço  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,address:e.target.value})
                        setErrorData({...errorData,address:''})
                    }} className={`form-control ${errorData.address ? 'is-invalid':''}`} name='address' value={inputData.address} id='address' />
                {errorData.address && (
                    <div className='invalid-feedback'>{errorData.address}</div>
                )}
                </div>
                </div>


                {/* <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='state'>State  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,state:e.target.value})
                        setErrorData({...errorData,state:''})
                    }} className={`form-control ${errorData.state ? 'is-invalid':''}`} name='state' value={inputData.state} id='state' />
                {errorData.state && (
                    <div className='invalid-feedback'>{errorData.state}</div>
                )}
                </div>
                </div> */}




                {inputData.type !== 'Hospital' && inputData.type !== 'ImagingDiagnosticClinic' &&  inputData.type !== 'SpecialMaterialsCompany' && inputData.type !== 'Laboratory' && inputData.type !== 'PathologyClinic' && (
                  <>
                {/* <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='city'>City  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,city:e.target.value})
                        setErrorData({...errorData,city:''})
                    }} className={`form-control ${errorData.city ? 'is-invalid':''}`} name='city' value={inputData.city} id='city' />
                {errorData.city && (
                    <div className='invalid-feedback'>{errorData.city}</div>
                )}
                </div>
                </div> */}

                </>

                )}


                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='zipCode'>CEP  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,zipCode:e.target.value})
                        setErrorData({...errorData,zipCode:''})
                    }} className={`form-control ${errorData.zipCode ? 'is-invalid':''}`} name='zipCode' value={inputData.zipCode} id='zipCode' />
                {errorData.zipCode && (
                    <div className='invalid-feedback'>{errorData.zipCode}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='streetAddress'>Endereço da Rua  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,streetAddress:e.target.value})
                        setErrorData({...errorData,streetAddress:''})
                    }} className={`form-control ${errorData.streetAddress ? 'is-invalid':''}`} name='streetAddress' value={inputData.streetAddress} id='streetAddress' />
                {errorData.streetAddress && (
                    <div className='invalid-feedback'>{errorData.streetAddress}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='houseNumber'>número da casa  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,houseNumber:e.target.value})
                        setErrorData({...errorData,houseNumber:''})
                    }} className={`form-control ${errorData.houseNumber ? 'is-invalid':''}`} name='houseNumber' value={inputData.houseNumber} id='houseNumber' />
                {errorData.houseNumber && (
                    <div className='invalid-feedback'>{errorData.houseNumber}</div>
                )}
                </div>
                </div>

            
                {inputData.type !== 'AnesthesiaGroup' && (
                  <>
                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='contactPersonName'>Nome da pessoa de contato  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,contactPersonName:e.target.value})
                        setErrorData({...errorData,contactPersonName:''})
                    }} className={`form-control ${errorData.contactPersonName ? 'is-invalid':''}`} name='contactPersonName' value={inputData.contactPersonName} id='contactPersonName' />
                {errorData.contactPersonName && (
                    <div className='invalid-feedback'>{errorData.contactPersonName}</div>
                )}
                </div>
                </div>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='contactPersonPhone'>contato pessoa telefone  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,contactPersonPhone:e.target.value})
                        setErrorData({...errorData,contactPersonPhone:''})
                    }} className={`form-control ${errorData.contactPersonPhone ? 'is-invalid':''}`} name='contactPersonPhone' value={inputData.contactPersonPhone} id='contactPersonPhone' />
                {errorData.contactPersonPhone && (
                    <div className='invalid-feedback'>{errorData.contactPersonPhone}</div>
                )}
                </div>
                </div>









                </>
                ) 
                }

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='whatsAppNumber'>Número do WhatsApp *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,whatsAppNumber:e.target.value})
                        setErrorData({...errorData,whatsAppNumber:''})
                    }} className={`form-control ${errorData.whatsAppNumber ? 'is-invalid':''}`} name='whatsAppNumber' value={inputData.whatsAppNumber} id='whatsAppNumber' />
                {errorData.whatsAppNumber && (
                    <div className='invalid-feedback'>{errorData.whatsAppNumber}</div>
                )}
                </div>
                </div>


                {inputData.type === 'AnesthesiaGroup' && (
                  <>

                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='address'>Endereço de trabalho  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,workAddress:e.target.value})
                        setErrorData({...errorData,workAddress:''})
                    }} className={`form-control ${errorData.workAddress ? 'is-invalid':''}`} name='workAddress' value={inputData.workAddress} id='workAddress' />
                {errorData.workAddress && (
                    <div className='invalid-feedback'>{errorData.workAddress}</div>
                )}
                </div>
                </div>

                {/* <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='city'>Work City  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,workCity:e.target.value})
                        setErrorData({...errorData,workCity:''})
                    }} className={`form-control ${errorData.workCity ? 'is-invalid':''}`} name='workCity' value={inputData.workCity} id='workCity' />
                {errorData.workCity && (
                    <div className='invalid-feedback'>{errorData.workCity}</div>
                )}
                </div>
                </div> */}


                {/* <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='workZipCode'>Work ZipCode  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,workZipCode:e.target.value})
                        setErrorData({...errorData,workZipCode:''})
                    }} className={`form-control ${errorData.workZipCode ? 'is-invalid':''}`} name='workZipCode' value={inputData.workZipCode} id='workZipCode' />
                {errorData.workZipCode && (
                    <div className='invalid-feedback'>{errorData.workZipCode}</div>
                )}
                </div>
                </div> */}


                </>
                ) 
                }





                {inputData.type === 'AnesthesiaGroup' && (
                  <>
                <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor='technicalManagerCRM'>Gerente Técnico CRM  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,technicalManagerCRM:e.target.value})
                        setErrorData({...errorData,technicalManagerCRM:''})
                    }} className={`form-control ${errorData.technicalManagerCRM ? 'is-invalid':''}`} name='technicalManagerCRM' value={inputData.technicalManagerCRM} id='technicalManagerCRM' />
                {errorData.technicalManagerCRM && (
                    <div className='invalid-feedback'>{errorData.technicalManagerCRM}</div>
                )}
                </div>
                </div>
                </>
                )}

</div>


                <div className='form-group'>
                    <label htmlFor='technicalManagerCRM'>Link social  *</label>
                    <div  style={{ marginTop:'10px'}}  >
                   {inputData.socialMedia && inputData.socialMedia.map((value,index)=>(
                    <React.Fragment key={index} style={{ display:'flex' }} >
                      <div className='row'>
                        <div className='col-md-6'>
                        {/* <div className='form-group'> */}
                            <label htmlFor=''>Link do Facebook</label>
                            <input className='form-control' disabled value={value.facebookLink} />
                        {/* </div> */}
                        </div>
                        <div className='col-md-6'>
                        {/* <div className='form-group'> */}
                            <label htmlFor=''>Link do Instagram</label>
                            <input className='form-control' disabled value={value.instagramLink} />
                        {/* </div> */}
                        </div>
                        </div> 
                    </React.Fragment>
                   ))}
                   </div>
                {errorData.technicalManagerCRM && (
                    <div className='invalid-feedback'>{errorData.technicalManagerCRM}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='citiesOfOperation'>Cidades de Operação  *</label>
                    <input type='text' disabled onChange={(e)=>{
                        setInputData({...inputData,citiesOfOperation:e.target.value})
                        setErrorData({...errorData,citiesOfOperation:''})
                    }} className={`form-control ${errorData.citiesOfOperation ? 'is-invalid':''}`} 
                    name='citiesOfOperation' 
                    value={inputData.citiesOfOperation && inputData.cities.map((city)=>city.name).join(',')  } 
                    
                    id='citiesOfOperation' />
                {errorData.citiesOfOperation && (
                    <div className='invalid-feedback'>{errorData.citiesOfOperation}</div>
                )}
                </div>
             

                   {/* Permissions Section */}
                   { inputData.type !== 'SpecialMaterialsCompany' &&  (
                    <>
                       <div className='card mt-5'>
              <div className='card-header'>
                <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>
                Permissão
                </h5>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-6'>
                  {inputData.type === 'Hospital' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'operatingRoomHourlyRate', label: 'Taxa horária da sala cirúrgica' },
                        { key: 'recoveryRoomHourlyRate', label: 'Taxa horária da sala de recuperação' },
                        { key: 'sharedRoomDailyRate', label: 'Diária Quarto Compartilhado' },
                        { key: 'privateRoomDailyRate', label: 'Diária Quarto Privado' },
                        { key: 'icuDailyRate', label: 'Diária UTI' },
                    
                       

                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}

                  {inputData.type === 'AnesthesiaGroup' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'managePricingPermmision', label: 'Gerenciar permissão de preços' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}

                  {inputData.type === 'PathologyClinic' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'nonNeoplasticPrice', label: 'Preço de plástico não Neo' },
                        { key: 'neoplasticPrice', label: 'Preço do Neo Plástico' },
                        { key: 'operativeFrozenPrice', label: 'Preço Operativo Congelado' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}

                  {inputData.type === 'ImagingDiagnosticClinic' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'ucoValue', label: 'Taxa de Valor do OAU' },
                        { key: 'imageCost', label: 'Custo da imagem' },
                        { key: 'managePricingPermmision', label: 'Gerenciar permissão de preços' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}


                  {inputData.type === 'Laboratory' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'ucoValue', label: 'Taxa de Valor do OAU' },
                        { key: 'managePricingPermmision', label: 'Gerenciar permissão de preços' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}
                   
                  </div>
                </div>
              </div>
            </div>
                    </>
                   )}
           

                <div className=' my-3'>
                {/* <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }}value={`Enviar`} /> */}
                <Link to='#' onClick={handleBack} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
