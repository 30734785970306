import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {  useEditFaqCategoryMutation,  useUpdateFaqCategoryMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { BASEURL } from '../../../Config';

export default function EditFAQCategory() {

const {id} = useParams()

const [EditFaqCategoryMutation] =  useEditFaqCategoryMutation();

const viewDetail = async(id)=>{
  const editResponse =   await EditFaqCategoryMutation({token,id})

  if(editResponse.data.status){
    setInputData(editResponse.data.data)

    setImagePreview(BASEURL+''+editResponse.data.data.image)
  }
}

useEffect(()=>{
    viewDetail(id);
},[])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        setErrorData(errors)
        return Object.keys(errors).length === 0;
    }


    const [UpdateFaqCategoryMutation] = useUpdateFaqCategoryMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const navigate = useNavigate()

const updateHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);

    form_data.append('_id',id)
    form_data.append('_method','PUT')

    const data = {
        '_id':id,'_method':'PUT','title':form_data.get('title')
    }

      const updateResponse = await UpdateFaqCategoryMutation({id,token,data})

    //   console.log(updateResponse);

      if(updateResponse.error){
        handleApiError(updateResponse.error)
      }

      if(updateResponse.data.status){
        // setInputData({title:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        setImagePreview('')

        nofitySuccess(updateResponse.data.message)
        setTimeout(() => {
          navigate("/admin/FAQ-category"); 
        }, 1000);
        
      }else{
        if(updateResponse.data.errors){
            setErrorData(updateResponse.data.errors)
        }
        notifyError(updateResponse.data.message);
      }

   }
}



  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Editar formulário de categoria de perguntas frequentes</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/FAQ-category`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={updateHandler}>
            <ToastContainer />


            <div className='form-group'>
                   <label htmlFor='title'>Título *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,title:e.target.value})
                        setErrorData({...errorData,title:''})
                    }} className={`form-control ${errorData.title ? 'is-invalid':''}`} name='title' value={inputData.title} id='title' />
                {errorData.title && (
                    <div className='invalid-feedback'>{errorData.title}</div>
                )}
                </div>

              
             

                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }}value={`Enviar`} />
                <Link to={`/admin/FAQ-category`} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
