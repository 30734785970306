// Loader.js
import React from 'react';
import './loader.css'; // Assuming you have some CSS for the loader

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
}

export default Loader;
