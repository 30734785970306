import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddFaqCategoryMutation, useAddPriceAnesthesiologistMutation, useAddPriceSurgeonMutation } from '../../../../services/ProjectApi';
import { getToken } from '../../../../services/Token';
import { useApiErrorHandler } from '../../../../utils/useApiErrorHandler';
import Layout from '../../../../master/Layout';
import { formDataToJson } from '../../../../services/utility';

export default function CreatePriceSurgeon() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.complexity || inputData.complexity.trim() === '') errors.complexity = 'Surgeon Complexity field is required';
        if (!inputData.price || inputData.price.trim() === '') errors.price = 'Price field is required';

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddPriceSurgeonMutation] = useAddPriceSurgeonMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const navigate = useNavigate()

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);
   const data = formDataToJson(form_data)
 
      const response = await AddPriceSurgeonMutation({token,data})

     

      if(response.error){
        handleApiError(response.error)
      }

      if(response.data.status){
        setInputData({price:'',complexity:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        setImagePreview('')

        nofitySuccess(response.data.message)

        setTimeout(() => {
          navigate("/admin/price-management-surgeon"); 
        }, 1000);
        
      }else{
        if(response.data.errors){
            setErrorData(response.data.errors)
        }
        notifyError(response.data.message);
      }




   }
}

  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Adicionar Formulário de Cirurgião de Preço</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/price-management-surgeon`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
                   <label htmlFor='complexity'>Complexidade do cirurgião *</label>
                    <input type='text' 
                     onChange={(e) => {
                        const { value } = e.target;
                     
                        // const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, complexity: value });

                        // if (value !== numericValue) {
                        //   setErrorData({
                        //     ...errorData,
                        //     complexity: "Please enter only digits",
                        //   });
                        // } else {
                          setErrorData({ ...errorData, complexity: "" });
                        // }
                      }}
                    
                    className={`form-control ${errorData.complexity ? 'is-invalid':''}`} name='complexity' value={inputData.complexity} id='complexity' />
                {errorData.complexity && (
                    <div className='invalid-feedback'>{errorData.complexity}</div>
                )}
                </div>

                <div className='form-group'>
                   <label htmlFor='price'>Preço *</label>
                    <input type='text' 
                    onChange={(e) => {
                        const { value } = e.target;
                     
                        // const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, price: value });

                        // if (value !== numericValue) {
                        //   setErrorData({
                        //     ...errorData,
                        //     price: "Please enter only digits",
                        //   });
                        // } else {
                          setErrorData({ ...errorData, price: "" });
                        // }
                      }}
                    
                    
                     className={`form-control ${errorData.price ? 'is-invalid':''}`} name='price' value={inputData.price} id='price' />
                {errorData.price && (
                    <div className='invalid-feedback'>{errorData.price}</div>
                )}
                </div>


                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }}value={`Enviar`} />
                <Link to={`/admin/price-management-surgeon`} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
