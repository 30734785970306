

import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';
import { useChangeStatusMutation, useContactUsListMutation, useQuestionListMutation, useRequestDemoListMutation } from '../../../services/ProjectApi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function ContactUs() {
    const notifyError = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list, setList] = useState([]);
    const [searchInput, setSearchInput] = useState({ keyword: '', status: '',question:'' });

    const [questionData,setQuestionData] = useState({});

    const token = getToken();
    const handleApiError = useApiErrorHandler();

    const [ContactUsListMutation] = useContactUsListMutation();

    const [QuestionListMutation] = useQuestionListMutation();

    // const [ChangeStatusMutation] = useChangeStatusMutation();
    // const [DeleteTestimonialMutation] = useDeleteTestimonialMutation();

    const getList = async () => {
        try {
            const response = await ContactUsListMutation({
                token,
                keyword: searchInput.keyword,
                status: searchInput.status,
                question:searchInput.question,
                page: currentPage
            });

            const questionResponse = await QuestionListMutation({token});

            if(questionResponse.data.status){
                setQuestionData(questionResponse.data.data)
            }

            if (response.error) {
                handleApiError(response.error);
                return;
            }

            if (response.data.status) {
                setList(response.data.data);
                setTotalPages(response.data.pagination.last_page);
            } else {
                notifyError("Failed to fetch testimonials.");
            }
        } catch (error) {
            console.log(error)
            notifyError("An unexpected error occurred.");
        }
    };

    useEffect(() => {
        getList();
    }, [currentPage]); 

    useEffect(() => {
        const delayFun = setTimeout(() => {
            setCurrentPage(1); 
            getList();
        }, 500); 
        return () => clearTimeout(delayFun);
    }, [searchInput]); 

    // const deleteData = async (id) => {
    //     if (window.confirm('Are you sure you want to delete this?')) {

    //         const deleteResponse = await DeleteTestimonialMutation({token,id})

    //         if(deleteResponse.data.status){
    //             getList();
    //             notifySuccess(deleteResponse.data.message);
    //         } else {
    //             notifyError(deleteResponse.data.message);
    //         }
    //     }
    // };

    // const changeStatus = async (table_name, id, status) => {
    //     try {
    //         const form_data = { table_name, id, status };
    //         const response = await ChangeStatusMutation({ token, form_data });

    //         if (response.data.status) {
    //             notifySuccess(response.data.message);
    //             getList();
    //         } else {
    //             notifyError(response.data.message);
    //         }
    //     } catch (error) {
    //         notifyError("Failed to change status.");
    //     }
    // };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [showModel,setShowModel] = useState(false)
    const [modelMessage,setModelMessage] = useState(false)

    const handleShow = (message)=>{
      setShowModel(true)
      setModelMessage(message);

    }

    const handleClose = ()=>{
        setShowModel(false)
        setModelMessage('')
    }

    // console.log(questionData)

    return (
        <Layout>
            <ToastContainer />
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 mt-2 pagetitle">
                            <h3>Lista de Contatos</h3>
                        </div>
                      
                    </div>
                </div>
                   <div className="card-body " style={{ overflowX: 'scroll' }}>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <input
                                type='text'
                                className='form-control'
                                name='search'
                                id='search'
                                onChange={(e) => {
                                setSearchInput({ ...searchInput, keyword: e.target.value })
                                setCurrentPage(1); 
                                }
                                }
                                value={searchInput.keyword}
                                placeholder='Pesquise aqui..'
                            />
                        </div>
                        <div className="col-md-3">
                            <select
                                name='question'
                                value={searchInput.question}
                                onChange={(e) =>{ setSearchInput({ ...searchInput, question: e.target.value })
                                setCurrentPage(1); 
                                }}
                                className='form-control'
                            >
                                <option value=''>--Selecione a pergunta--</option>
                                { questionData.length > 0 && questionData.map((value,index)=>(
                                    <option value={value._id} key={index}>{value.title}</option>
                                ))  }
                            </select>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Primeiro nome</th>
                                <th scope="col">Sobrenome</th>
                                <th scope="col">Email</th>
                                <th scope="col">nome da empresa</th>
                                <th scope="col">Telefone</th>
                                <th scope="col">CEP</th>
                                {/* <th scope="col">Question</th> */}
                                <th scope="col">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.length > 0 ? list.map((value, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                        <td>{value.first_name}</td>
                                        <td>{value.last_name}</td>
                                        <td>{value.email}</td>
                                        <td>{value.company_name}</td>
                                        <td>{value.phone}</td>
                                        <td>{value.zip_code}</td>
                                        {/* <td>{value.question.title}</td> */}
                                        <td>
                                        <Link onClick={()=>{ handleShow(`${value.company_name}`) }}  className='btn btn-danger btn-sm mx-1'><i className="bi bi-eye"></i></Link>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className='text-center'>Nenhum registro encontrado</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
                </div>
            </div>

            <Modal show={showModel} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modelMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>


        </Layout>
    );
}
