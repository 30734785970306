import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
   <>
     <footer id="footer" class="footer">
    <div class="copyright">
      &copy; Direitos autorais <strong><span>QMedicina</span></strong>. Todos os direitos reservados
    </div>
    <div class="credits">
    Projetado por <Link to={`/`} >QMedicina</Link>
    </div>
  </footer>
   </>
  )
}
