import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeToken } from '../services/Token';

export const useApiErrorHandler = () => {
    const navigate = useNavigate();

    const handleApiError = (error) => {
        if (error?.data?.statusCode === 403) {
            toast.error('You are not authorized to perform this action.');
            removeToken();
            navigate('/');
        } else {
            // Handle other errors
            toast.error('An unexpected error occurred.');
        }
    };

    return handleApiError;
};
