import { useEffect, useState } from "react";
import AppRoutes from "./routes/AppRoutes";
import { useLocation } from "react-router-dom";
import Loader from "./components/loader/Loader";

import { confirmAlert } from 'react-confirm-alert'; // Import the library
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS

function App() {

  const [loading, setLoading] = useState(false);
  const location = useLocation();


  useEffect(() => {
    setLoading(true);
    
    const handleComplete = () => setLoading(false);
    
    const timeout = setTimeout(handleComplete, 500); 
    return () => clearTimeout(timeout);
  }, [location]);

  return (
  <>
   {loading && <Loader />}
   <AppRoutes />
  </>
  );
}

export default App;
