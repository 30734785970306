import React, { useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddCbhpmMutation, useAddFaqCategoryMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { formDataToJson } from '../../../services/utility';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';
import { Form } from 'react-bootstrap';

export default function CreateCbhpm() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)
        
    const [inputData,setInputData] = useState({ isShowPrescription: 'no' ,isOfficeVisit: 'no' });
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.groupId || inputData.groupId.trim() === '') errors.groupId = 'O campo ID do grupo é obrigatório';

        if (!inputData.groupDescription || inputData.groupDescription.trim() === '') errors.groupDescription = 'Group O campo de descrição é obrigatório';

        if (!inputData.subgroupId || inputData.subgroupId.trim() === '') errors.subgroupId = 'O campo ID do subgrupo é obrigatório';

        if (!inputData.subgroupDescription || inputData.subgroupDescription.trim() === '') errors.subgroupDescription = 'Sub Group O campo de descrição é obrigatório';

        if (!inputData.procedureID || inputData.procedureID.trim() === '') errors.procedureID = 'O campo ID do procedimento é obrigatório';

        if (!inputData.descriptionOfTheProcedure || inputData.descriptionOfTheProcedure.trim() === '') errors.descriptionOfTheProcedure = 'O campo Descrição do Procedimento é obrigatório';

        if (!inputData.groupId || inputData.groupId.trim() === '') errors.groupId = 'O campo ID do grupo é obrigatório';

        if (!inputData.groupId || inputData.groupId.trim() === '') errors.groupId = 'O campo ID do grupo é obrigatório';



        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddCbhpmMutation] = useAddCbhpmMutation();

    const [disableBtn,setDisableBtn] = useState(false)

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);
    const navigate = useNavigate()

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    setDisableBtn(true)
    const form_data = new FormData(e.currentTarget);
    form_data.append('websiteProcedureDescription', inputData.websiteProcedureDescription);

    form_data.append('websiteProcedurePrescription', inputData.websiteProcedurePrescription);
    form_data.append('officeVisitDescription', inputData.officeVisitDescription);
    if(inputData.isShowPrescription === 'no'){
        form_data.append('isShowPrescription', inputData.isShowPrescription);

    }
  
    const data = formDataToJson(form_data)


 
      const response = await AddCbhpmMutation({token,data})

     

      if(response.error){
        handleApiError(response.error)
      }

      if(response.data.status){

      setInputData({groupId:'',groupDescription:'',subgroupId:'',subgroupDescription:'',
        procedureID:'',descriptionOfTheProcedure:'',Percentage:'',Porte:'',operatingCost:'',auxNo:'',
        anestesGate:'',moviesOrDoc:'',incidence:'',uR:'',websiteProcedureDescription:'',isOfficeVisit:''
      })


        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        setImagePreview('')

        nofitySuccess(response.data.message)
        setTimeout(() => {
            navigate("/admin/Cbhpm"); 
          }, 1000);
        
      }else{
        if(response.data.errors){
            setErrorData(response.data.errors)
        }
        notifyError(response.data.message);
      }

      setDisableBtn(false)


   }
}


const {MyCustomUploadAdapterPlugin} = useCkeditorUploadAdapter();


const editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
    extraPlugins: [MyCustomUploadAdapterPlugin],
};


  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Adicionar Formulário CBHPM</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/cbhpm`} className='btn btn-danger'>Voltar</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
<label htmlFor='groupId'>ID do grupo *</label>
                    <input type='text' value={inputData.groupId} onChange={(e)=>{
                        setInputData({...inputData,groupId:e.target.value})
                        setErrorData({...errorData,groupId:''})
                    }} className={`form-control ${errorData.groupId ? 'is-invalid':''}`} name='groupId'  id='groupId' />
                {errorData.groupId && (
                    <div className='invalid-feedback'>{errorData.groupId}</div>
                )}
                </div>

                <div className='form-group'>
                   <label htmlFor='groupDescription'>Descrição do grupo *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,groupDescription:e.target.value})
                        setErrorData({...errorData,groupDescription:''})
                    }} className={`form-control ${errorData.groupDescription ? 'is-invalid':''}`} name='groupDescription' value={inputData.groupDescription} id='groupDescription' />
                {errorData.groupDescription && (
                    <div className='invalid-feedback'>{errorData.groupDescription}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='subgroupId'>ID do subgrupo *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,subgroupId:e.target.value})
                        setErrorData({...errorData,subgroupId:''})
                    }} className={`form-control ${errorData.subgroupId ? 'is-invalid':''}`} name='subgroupId' value={inputData.subgroupId} id='subgroupId' />
                {errorData.subgroupId && (
                    <div className='invalid-feedback'>{errorData.subgroupId}</div>
                )}
                </div>


                <div className='form-group'>
                    <label htmlFor='subgroupDescription'>Descrição do subgrupo *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,subgroupDescription:e.target.value})
                        setErrorData({...errorData,subgroupDescription:''})
                    }} className={`form-control ${errorData.subgroupDescription ? 'is-invalid':''}`} name='subgroupDescription' value={inputData.subgroupDescription} id='subgroupDescription' />
                {errorData.subgroupDescription && (
                    <div className='invalid-feedback'>{errorData.subgroupDescription}</div>
                )}
                </div>

                <div className='form-group'>
                   <label htmlFor='procedureID'>ID do procedimento *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,procedureID:e.target.value})
                        setErrorData({...errorData,procedureID:''})
                    }} className={`form-control ${errorData.procedureID ? 'is-invalid':''}`} name='procedureID' value={inputData.procedureID} id='procedureID' />
                {errorData.procedureID && (
                    <div className='invalid-feedback'>{errorData.procedureID}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='descriptionOfTheProcedure'>Descrição do procedimento *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,descriptionOfTheProcedure:e.target.value})
                        setErrorData({...errorData,descriptionOfTheProcedure:''})
                    }} className={`form-control ${errorData.descriptionOfTheProcedure ? 'is-invalid':''}`} name='descriptionOfTheProcedure' value={inputData.descriptionOfTheProcedure} id='descriptionOfTheProcedure' />
                {errorData.descriptionOfTheProcedure && (
                    <div className='invalid-feedback'>{errorData.descriptionOfTheProcedure}</div>
                )}
                </div>

                <div className='form-group'>
                   <label htmlFor='Porta'>Porta *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,Porte:e.target.value})
                        setErrorData({...errorData,Porte:''})
                    }} className={`form-control ${errorData.Porte ? 'is-invalid':''}`} name='Porte' value={inputData.Porte} id='Porte' />
                {errorData.Porte && (
                    <div className='invalid-feedback'>{errorData.Porte}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='Percentage'>Percentage </label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,Percentage:e.target.value})
                        setErrorData({...errorData,Percentage:''})
                    }} className={`form-control ${errorData.Percentage ? 'is-invalid':''}`} name='Percentage' value={inputData.Percentage} id='Percentage' />
                {errorData.Percentage && (
                    <div className='invalid-feedback'>{errorData.Percentage}</div>
                )}
                </div>

                

                <div className='form-group'>
                   <label htmlFor='operatingCost'>Custo Operacional *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,operatingCost:e.target.value})
                        setErrorData({...errorData,operatingCost:''})
                    }} className={`form-control ${errorData.operatingCost ? 'is-invalid':''}`} name='operatingCost' value={inputData.operatingCost} id='operatingCost' />
                {errorData.operatingCost && (
                    <div className='invalid-feedback'>{errorData.operatingCost}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='auxNo'>AuxNo *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,auxNo:e.target.value})
                        setErrorData({...errorData,auxNo:''})
                    }} className={`form-control ${errorData.auxNo ? 'is-invalid':''}`} name='auxNo' value={inputData.auxNo} id='auxNo' />
                {errorData.auxNo && (
                    <div className='invalid-feedback'>{errorData.auxNo}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='anestesGate'>Portão Anestes *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,anestesGate:e.target.value})
                        setErrorData({...errorData,anestesGate:''})
                    }} className={`form-control ${errorData.anestesGate ? 'is-invalid':''}`} name='anestesGate' value={inputData.anestesGate} id='anestesGate' />
                {errorData.anestesGate && (
                    <div className='invalid-feedback'>{errorData.anestesGate}</div>
                )}
                </div>

                <div className='form-group'>
                   <label htmlFor='moviesOrDoc'>Filmes ou documentos *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,moviesOrDoc:e.target.value})
                        setErrorData({...errorData,moviesOrDoc:''})
                    }} className={`form-control ${errorData.moviesOrDoc ? 'is-invalid':''}`} name='moviesOrDoc' value={inputData.moviesOrDoc} id='moviesOrDoc' />
                {errorData.moviesOrDoc && (
                    <div className='invalid-feedback'>{errorData.moviesOrDoc}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='incidence'>Incidência *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,incidence:e.target.value})
                        setErrorData({...errorData,incidence:''})
                    }} className={`form-control ${errorData.incidence ? 'is-invalid':''}`} name='incidence' value={inputData.incidence} id='incidence' />
                {errorData.incidence && (
                    <div className='invalid-feedback'>{errorData.incidence}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='uR'>UR *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,uR:e.target.value})
                        setErrorData({...errorData,uR:''})
                    }} className={`form-control ${errorData.uR ? 'is-invalid':''}`} name='uR' value={inputData.uR} id='uR' />
                {errorData.uR && (
                    <div className='invalid-feedback'>{errorData.uR}</div>
                )}
                </div>


                <div className='form-group'>
                   <label htmlFor='websiteProcedureDescription'>Descrição do procedimento do site</label>
                  
                    <div className={`ck-editor-container ${errorData.error ? 'is-invalid':''}`}>
                    <CKEditor 
                        name="websiteProcedureDescription"
                         id="websiteProcedureDescription"
                         data={inputData.websiteProcedureDescription}
                        editor={ClassicEditor}
                         onChange={(e,editor)=>{
                            const data = editor.getData();
                            setInputData(prevData=>({...prevData,websiteProcedureDescription:data}))
                            setErrorData(prevData=>({...prevData,websiteProcedureDescription:''}))
                            }
                        }
                        config={editorConfig}
                    />
                    </div>
                    {errorData.websiteProcedureDescription && (
                        <div className='invalid-feedback d-block'>{errorData.websiteProcedureDescription}</div>
                    )}
                    
                </div>

                    <Form.Group> 
                    <div className="form-group">
                    <Form.Check type='checkbox'>
                        <Form.Check.Input checked={inputData.isShowPrescription === 'yes'} value={`yes`}
                        name='isShowPrescription'
                        onChange={(e) => {
                        setInputData((prevData) => ({
                            ...prevData,
                            isShowPrescription: e.target.checked ? 'yes' : 'no', // Toggle between 'yes' and 'no'
                        }));
                        }}

                        />
                        <Form.Check.Label>
                        <label htmlFor='websiteProcedurePrescription'>Mostrar prescrição do procedimento do site</label>
                        </Form.Check.Label>
                    </Form.Check>
                    </div>
                    </Form.Group>


                    {inputData.isShowPrescription === 'yes' && (
                        <>
                        <div className='form-group'>
                    <label htmlFor='websiteProcedurePrescription'>Prescrição de procedimento de site</label>
                  
                    <div className={`ck-editor-container ${errorData.error ? 'is-invalid':''}`}>
                    <CKEditor 
                        name="websiteProcedurePrescription"
                         id="websiteProcedurePrescription"
                         data={inputData.websiteProcedurePrescription}
                        editor={ClassicEditor}
                         onChange={(e,editor)=>{
                            const data = editor.getData();
                            setInputData(prevData=>({...prevData,websiteProcedurePrescription:data}))
                            setErrorData(prevData=>({...prevData,websiteProcedurePrescription:''}))
                            }
                        }
                        config={editorConfig}
                    />
                    </div>
                    {errorData.websiteProcedurePrescription && (
                        <div className='invalid-feedback d-block'>{errorData.websiteProcedurePrescription}</div>
                    )}
                    
                </div>
                        </>
                    )}

                     <Form.Group> 
                                        <div className="form-group">
                                        <Form.Check type='checkbox'>
                                            <Form.Check.Input checked={inputData.isOfficeVisit === 'yes'} value={inputData.isOfficeVisit}
                                            name='isOfficeVisit'
                                            onChange={(e) => {
                                            setInputData((prevData) => ({
                                                ...prevData,
                                                isOfficeVisit: e.target.checked ? 'yes' : 'no', // Toggle between 'yes' and 'no'
                                            }));
                                            }}
                    
                                            />
                                            <Form.Check.Label>
                                            <label htmlFor='isOfficeVisit'>Is Visit Office ?</label>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        </div>
                                        </Form.Group>

                      <div className='form-group'>
                                       <label htmlFor='officeVisitDescription'>Office Visit Description</label>
                                      
                                        <div className={`ck-editor-container ${errorData.error ? 'is-invalid':''}`}>
                                        <CKEditor 
                                            name="officeVisitDescription"
                                             id="officeVisitDescription"
                                             data={inputData.officeVisitDescription}
                                            editor={ClassicEditor}
                                             onChange={(e,editor)=>{
                                                const data = editor.getData();
                                                setInputData(prevData=>({...prevData,officeVisitDescription:data}))
                                                setErrorData(prevData=>({...prevData,officeVisitDescription:''}))
                                                }
                                            }
                                            config={editorConfig}
                                        />
                                        </div>
                                        {errorData.officeVisitDescription && (
                                            <div className='invalid-feedback d-block'>{errorData.officeVisitDescription}</div>
                                        )}
                                        
                                    </div>
              


                <div className=' my-3'>
                <input type='submit' disabled={disableBtn} className='btn btn-primary mr-3' style={{ marginRight:`5px` }}value={`Enviar`} />
                <Link to={`/admin/cbhpm`} className='btn btn-danger ml-3'>Voltar</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
