import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data:{}
}

export const userSlice = createSlice({
  name: 'user_info',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.data = action.payload
    },
    unSetUserInfo: (state, action) => {
        state.data = {}
      },
  },
})

export const { setUserInfo, unSetUserInfo } = userSlice.actions

export default userSlice.reducer