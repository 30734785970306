import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {  useAddPageMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './createFAQ.css';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';

export default function CreatePage() {

      const {MyCustomUploadAdapterPlugin} = useCkeditorUploadAdapter();

    const nofitySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'O campo título é obrigatório';
        if (!inputData.description || inputData.description.trim() === '') errors.description = 'O campo de descrição é obrigatório';

        setErrorData(errors);
        return Object.keys(errors).length === 0;
    }

    const [AddPageMutation] = useAddPageMutation();
    const token = getToken();
    const handleApiError = useApiErrorHandler();

    const navigate = useNavigate()
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const data = {
                title: inputData.title,
                description: inputData.description,
            };

            const response = await AddPageMutation({ token, data });

            if (response.error) {
                handleApiError(response.error);
            }

            if (response.data.status) {
                setInputData({ title: '', description: '' });
                nofitySuccess(response.data.message)
                setTimeout(() => {
                    navigate("/admin/page"); 
                  }, 1000);;
            } else {
                if (response.data.errors) {
                    setErrorData(response.data.errors);
                }
                notifyError(response.data.message);
            }
        }
    }


 

    const editorConfig = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
        extraPlugins: [MyCustomUploadAdapterPlugin],
    };



    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setInputData({ ...inputData, description: data });
        setErrorData({ ...errorData, description: '' });
    };

    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6 '>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add Page Form</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/page`} className='btn btn-danger'>Voltar</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={submitHandler}>
                        <ToastContainer />

                        <div className='form-group'>
                           <label htmlFor='title'>Título *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({...inputData,title:e.target.value});
                                    setErrorData({...errorData,title:''});
                                }}
                                className={`form-control ${errorData.title ? 'is-invalid' : ''}`}
                                name='title'
                                value={inputData.title}
                                id='title'
                            />
                            {errorData.title && (
                                <div className='invalid-feedback'>{errorData.title}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='description'>Descripiton *</label>
                            <div className={`ck-editor-container ${errorData.description ? 'is-invalid' : ''}`}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={inputData.description}
                                    name='description'
                                    id='description'
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        setInputData(prevData => ({
                                            ...prevData,
                                            description: data
                                        }));
                                        setErrorData(prevErrors => ({
                                            ...prevErrors,
                                            description: ''
                                        }));
                                       
                                    }}
                                    // onChange={handleEditorChange}
                                    config={editorConfig}
                                />
                            </div>
                            {errorData.description && (
                                <div className='invalid-feedback d-block'>{errorData.description}</div>
                            )}
                        </div>

                        <div className='my-3'>
                            <input type='submit' className='btn btn-primary mr-3' style={{ marginRight: `5px` }}value={`Enviar`} />
                            <Link to={`/admin/page`} className='btn btn-danger ml-3'>Voltar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}
